// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  AuthnData,
  AuthnMethod,
  TraceContext,
  AgentEvent,
} from '../authn-module/types';
import {
  AuthnSection,
  ConfigSection,
  EventSection,
  MemorySection,
  OtelSection,
  WindowSection,
} from './sections';

// re-export useful types so that there are no direct imports from outside current directory
export { AuthnMethod, AgentEvent } from '../authn-module/types';
export type {
  TraceContext,
  JourneyId,
  FactorFilter,
} from '../authn-module/types';

export interface AgentApi {
  otel: Omit<OtelSection, '_memory'>;
  authn: Omit<AuthnSection, '_memory'>;
  event: Omit<EventSection, '_memory'>;
  window: Omit<WindowSection, '_memory'>;
  _config: Omit<ConfigSection, '_memory'>;
  _memory: MemorySection;
}

export type AgentEventHandler =
  | FactorDataReadyEventHandler
  | FactorChangedEventHandler
  | ErrorEventHandler
  | CloseWindowEventHandler
  | AppAuthenticationStatusEventHandler
  | AppAuthenticationCanceledEventHandler;

export type FactorDataReadyEventHandler = EventHandlerCallback<{
  traceContext: TraceContext;
  authnData: AuthnData;
}>;

export type FactorChangedEventHandler = EventHandlerCallback<{
  traceContext: TraceContext;
  factorList: AuthnMethod[];
}>;

export type ErrorEventHandler = EventHandlerCallback<void>;

export type CloseWindowEventHandler = EventHandlerCallback<{
  traceContext: TraceContext;
}>;

export type AppAuthenticationStatusEventHandler = EventHandlerCallback<{
  traceContext: TraceContext;
  statusData: StatusData;
}>;

export type AppAuthenticationCanceledEventHandler = EventHandlerCallback<{
  traceContext: TraceContext;
}>;

export type EventHandlerCallback<Data> = (
  eventName: AgentEvent,
  data: Data,
) => void;

export type StatusData = {
  statusCode: AuthenticationStatusCode;
};

export enum AuthenticationReadinessStatusCode {
  READY = 'ready',
  NOT_READY = 'not-ready',
}

export enum AuthenticationResultStatusCode {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export type AuthenticationStatusCode =
  | AuthenticationReadinessStatusCode
  | AuthenticationResultStatusCode;

export type AgentWindowCloseHandler = EventHandlerCallback<{
  traceContext: TraceContext;
}>;
