// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPushStatus } from '../../../api/api';
import { ModelData, PushStatus } from '../../../types';
import phoneImg from '../../../images/phoneImg.png';
import { SpanNames, tracer } from '../../../tracing';
import { getLatestUsedDeviceModel } from '../deviceModel.util';

interface IProps {
  onPushTimeOut: () => void;
  onPushAccepted: (samlToken: string) => void;
  modelData?: ModelData[];
}

export const UI_TIMEOUT_MILLIS = 10000;
export const POLLING_INTERVAL_MILLIS = 250;
let stopPolling = false;

export const PushNotification = ({
  onPushTimeOut,
  onPushAccepted,
  modelData,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const deviceModel = modelData && getLatestUsedDeviceModel(modelData);

  const evaluatePushStatus = useCallback(
    (tenantId: string) => {
      getPushStatus(tenantId).then(response => {
        tracer.endSpan(SpanNames.GET_PUSH_STATUS);
        if (response.status === PushStatus.PUSH_TIMEOUT) {
          onPushTimeOut();
          tracer.endSpan(SpanNames.GET_PUSH_STATUS);
        } else if (response.status === PushStatus.ACCEPTED) {
          onPushAccepted(response.samlToken || '');
          tracer.endSpan(SpanNames.GET_PUSH_STATUS);
        } else if (!stopPolling) {
          setTimeout(() => {
            evaluatePushStatus(tenantId);
          }, POLLING_INTERVAL_MILLIS);
        }
      });
    },
    [onPushAccepted, onPushTimeOut],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('tenantId')) {
      const tenantId: string = queryParams.get('tenantId') as string;
      tracer.startSpan(SpanNames.GET_PUSH_STATUS);
      evaluatePushStatus(tenantId);
    }
  }, [evaluatePushStatus]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      onPushTimeOut();
      clearTimeout(timerId);
      // Can't use useState here as it's inside a setTimeout:
      stopPolling = true; // stops the UI from infinitely polling
    }, UI_TIMEOUT_MILLIS);
    return () => clearTimeout(timerId);
  }, [onPushTimeOut]);

  return (
    <form>
      <div data-testid="push-info-label" className="formLabel subtitle">
        {t('login.push.info')} {deviceModel}
      </div>
      <img src={phoneImg} alt="phoneImg" style={{ marginBottom: '36px' }} />
      <div data-testid="no-push-notification-label" className="formLabel">
        <span>{t('login.push.not-notified')}</span>&nbsp;
        <a href="#">{t('login.push.try-again')}</a>
        {/* <>{t('login.push.not-notified')} <a href={'/'}> {t('login.try-again')}</a></> */}
      </div>
    </form>
  );
};
