// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useContext, useEffect } from 'react';
import { applyInterceptors, ejectInterceptors } from '../../api/interceptors';
import { AuthnMethod } from '../../types';
import { FactorError, GeneralMessage } from '../../components/login';
import { ProxCardContainer, UsernamePasswordContainer } from './components';
import { AppContext } from '../../App';
import {
  clearErrorBanners,
  clearErrors,
  setBanners,
  startReset,
  stopReset,
  fetchFactorOptions,
  subscribeToFactorChangedAction,
} from './store/actions';
import {
  BANNER_DISPLAY_TIMEOUT_SEC,
  ErrorMessages,
  INLINE_ENROLLMENT_DISPLAY_TIMEOUT_SEC,
  USER_ACTIVITIES_EVENTS,
} from './constants';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import {
  AuthBannerSize,
  AuthBannerType,
  createAuthErrorBannerMsg,
} from '@imprivata-cloud/astra-ui-component-library';
import { ImprIdContainer } from './components/ImprIdContainer';
import { EnrollmentContainer } from './components/EnrollmentContainer';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginState } from './store/types';

export const LoginPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: ILoginState) => rootState);

  const { t } = useTranslation();

  useEffect(() => {
    applyInterceptors(dispatch);
    return () => {
      ejectInterceptors();
    };
  }, [dispatch]);

  const { setShowLogo, showLogo, orgPreferences } = useContext(AppContext);

  const {
    isInitialState,
    isLoading,
    fatalError,
    tenantIdError,
    success,
    authnMethods,
    currentAuthnMethod,
    isStartGetFactorFlow,
    authError,
    pushNotificationExpiredError,
    isEnrollmentCeremony,
  } = state;

  const fatalErrorMessage = fatalError?.message;
  const errorToDisplay =
    fatalErrorMessage ||
    tenantIdError ||
    authError ||
    pushNotificationExpiredError;

  useEffect(() => {
    if (
      errorToDisplay &&
      errorToDisplay !== ErrorMessages.INCORRECT_TENANT_ID &&
      errorToDisplay !== ErrorMessages.NOT_ENROLLED_CARD
    ) {
      const errorKey = 'errors.' + errorToDisplay;
      if (
        errorToDisplay === 'password-expired' ||
        errorToDisplay === 'PASSWORD_EXPIRED'
      ) {
        dispatch(
          setBanners([
            {
              type: AuthBannerType.INFO,
              primary: t(errorKey as never),
              size: AuthBannerSize.TALL,
            },
          ]),
        );
      } else {
        const errorMsg =
          errorToDisplay === ErrorMessages.UXID_MULTIPLEUSERS
            ? t(errorKey as never, { username: orgPreferences?.userNameLabel })
            : t(errorKey as never);

        dispatch(setBanners([createAuthErrorBannerMsg(errorMsg)]));
      }
    } else {
      dispatch(clearErrorBanners());
    }

    const timeoutId = setTimeout(() => {
      dispatch(clearErrors());
    }, BANNER_DISPLAY_TIMEOUT_SEC * 1000);
    return () => clearTimeout(timeoutId);
  }, [errorToDisplay, dispatch, t, orgPreferences]);

  // timeout for inline enrollment screen
  useIdleTimer({
    timeout: INLINE_ENROLLMENT_DISPLAY_TIMEOUT_SEC * 1000,
    events: USER_ACTIVITIES_EVENTS,
    onIdle: () => {
      dispatch(startReset());
    },
    name: 'inline-enrollment-screen-timeout',
  });

  useEffect(() => {
    if (!isInitialState && !fatalError?.retryConnection) {
      return;
    }
    if (isInitialState) {
      dispatch(stopReset());
    }

    // This has to happen later than AuthnModule's extraction of
    // needed information from appAccessContext (username).
    // Without this timeout, it happens too early:
    setTimeout(() => {
      dispatch(fetchFactorOptions.request());
    }, 0);
  }, [dispatch, isInitialState, fatalError, isStartGetFactorFlow]);

  useEffect(() => {
    if (setShowLogo) {
      setShowLogo(!isEnrollmentCeremony);
    }
  }, [setShowLogo, showLogo, dispatch, isEnrollmentCeremony]);

  useEffect(() => {
    dispatch(subscribeToFactorChangedAction());
  }, [dispatch]);

  const getComponentToRender = () => {
    switch (true) {
      case !!tenantIdError:
        return <FactorError />;
      case success:
        return (
          <GeneralMessage>
            {t('login.success')}
            <br />
            {t('login.ok')}
          </GeneralMessage>
        );
      case isEnrollmentCeremony:
        return <EnrollmentContainer orgPreferences={orgPreferences} />;
      case currentAuthnMethod?.factorType === AuthnMethod.USERNAME_PASSWORD:
        return <UsernamePasswordContainer orgPreferences={orgPreferences} />;
      case currentAuthnMethod?.factorType === AuthnMethod.PROX:
        return <ProxCardContainer orgPreferences={orgPreferences} />;
      case currentAuthnMethod?.factorType === AuthnMethod.IMPR_ID:
        return <ImprIdContainer orgPreferences={orgPreferences} />;
      case !!currentAuthnMethod:
        return (
          <h1>
            {t('errors.unknown-auth-method', {
              method: currentAuthnMethod?.factorType,
            })}
          </h1>
        );
      // when there is no authn methods it should not show any error since the UI will
      // be hidden by the agent
      case authnMethods.length === 0 && !fatalError?.message:
        return <div />;
      case isLoading:
        return <div>{t('login.loading')}</div>;
      default:
        return <FactorError />;
    }
  };

  return (
    <div className={showLogo ? 'formContainer' : 'fullPage'}>
      {getComponentToRender()}
    </div>
  );
};

export default LoginPage;
