// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useState, useEffect } from 'react';
import { OrgPreferencesPayload } from '../../../types';
import { startReset, enrollCardAction, clearErrors } from '../store/actions';
import { EnrollProxForm } from '../../../components/login/enroll-prox-form';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginState } from '../store/types';

const stateSelector = ({ isLoading, authError }: ILoginState) => ({
  isLoading,
  authError,
});

export function EnrollmentContainer(props: {
  orgPreferences?: OrgPreferencesPayload;
}): JSX.Element {
  const dispatch = useDispatch();
  const { isLoading, authError } = useSelector(stateSelector);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (authError) {
      setUsername('');
      setPassword('');
    }
  }, [authError]);

  const handleEnrollProxForm = (
    enrolleeUsername: string,
    enrolleePassword: string,
  ): void => {
    if (authError) {
      dispatch(clearErrors());
    }
    setUsername(enrolleeUsername);
    setPassword(enrolleePassword);

    dispatch(
      enrollCardAction.request({
        username: enrolleeUsername,
        password: enrolleePassword,
      }),
    );
  };

  const handleEnrollProxFormCancel = (): void => {
    dispatch(startReset());
  };

  return (
    <div className="enrollmentContainer">
      <EnrollProxForm
        initUsernameValue={username}
        initPasswordValue={password}
        disabled={isLoading}
        onSubmit={handleEnrollProxForm}
        onCancel={handleEnrollProxFormCancel}
        userNameLabel={props.orgPreferences?.userNameLabel}
        passwordLabel={props.orgPreferences?.passwordLabel}
      />
    </div>
  );
}
