// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, switchMap, Observable } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  authenticateAction,
  clearSession,
  subscribeToAuthnDataReadyAction,
} from '../actions';
import { Action } from '../rootAction';
import { EpicDependencies } from '../../../../store/types';
import { ILoginState } from '../types';

export const authnDataReadyEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'subscribeToAuthnDataReady'>
> = (action$, _, dependencies) => {
  const { subscribeToAuthnDataReady } = dependencies;

  return action$.pipe(
    filter(isActionOf(subscribeToAuthnDataReadyAction)),
    switchMap(() => {
      return new Observable<Action>(subscription => {
        subscribeToAuthnDataReady((_1, { traceContext, authnData }) => {
          subscription.next(clearSession());
          subscription.next(
            authenticateAction.request({ authnData, traceContext }),
          );
        });
      });
    }),
  );
};
