// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createStore, applyMiddleware, compose, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  createSession,
  enroll,
  authenticate,
  getFactorOptions,
} from '../api/api';
import loginReducer, { initialState } from '../pages/login/store/reducer';
import { loginEpic } from '../pages/login/store/epics';
import { ILoginState } from '../pages/login/store/types';
import { Action } from '../pages/login/store/rootAction';
import {
  EpicDependencies,
  OuterDependencies,
  InnerDependencies,
} from './types';
import { getQueryParams } from '../utils/queryParams';
import { RecursivePartial } from '../types';

const isProduction = process.env.NODE_ENV === 'production';

const composeEnhancers =
  (!isProduction &&
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Authn UI Module' })) ||
  compose;

const innerDeps: InnerDependencies = {
  getFactorOptions,
  createSession,
  authenticate,
  enroll,
  getQueryParams,
};

export const configureStore = (
  outerDeps: OuterDependencies,
): Store<ILoginState> => {
  const epicMiddleware = createEpicMiddleware<
    Action,
    Action,
    ILoginState,
    EpicDependencies
  >({
    dependencies: {
      ...innerDeps,
      ...outerDeps,
    },
  });

  const store = createStore(
    loginReducer,
    composeEnhancers(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(loginEpic);

  return store;
};

// to be used in unit tests
export const createShallowStore = (
  partialState: RecursivePartial<ILoginState> = {},
): Store<ILoginState> => {
  // `isLoading` is true in initialState.
  // `isLoading: false` is more convenient for unit testing.
  // No need to override it in every test.
  const state = { ...initialState, isLoading: false, ...partialState };

  return createStore(loginReducer, state as ILoginState);
};
