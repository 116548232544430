// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, InputBox } from '@imprivata-cloud/astra-ui-component-library';
import { useTranslation } from 'react-i18next';
import phoneImg from '../../../images/phoneImg.png';
import { ModelData } from '../../../types';
import { getLatestUsedDeviceModel } from '../deviceModel.util';

interface IProps {
  initValue?: string;
  error?: string;
  disabled?: boolean;
  userNameLabel?: string;
  modelData?: ModelData[];
  onSubmit: (token: string) => void;
}

export const TokenEntryForm = ({
  error = '',
  disabled = false,
  onSubmit,
  modelData,
}: IProps): JSX.Element => {
  const [token, setToken] = useState('');
  const [errorMsg, setErrorMsg] = useState(error);
  const { t } = useTranslation();
  const deviceModel = modelData && getLatestUsedDeviceModel(modelData);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value.trim());
    if (errorMsg) {
      setErrorMsg('');
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(token);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label
        htmlFor="tokenInput"
        data-testid="token-input-label"
        className="formLabel subtitle"
      >
        {t('login.token.label')} {deviceModel}
      </label>
      <img src={phoneImg} alt="phoneImg" style={{ marginBottom: '36px' }} />
      <InputBox
        style={{ marginBottom: '10px' }}
        type="text"
        placeholder={''}
        value={token}
        data-testid={'token-input'}
        onChange={handleChange}
        error={errorMsg}
        autoFocus={!disabled}
        disabled={disabled}
        width="100%"
        size="large"
        className="primary-body"
        id="tokenInput"
      />
      <Button
        astraButtonType={['major']}
        label={t('actions.continue')}
        disabled={!token.length || disabled}
        data-testid="submit-button"
        type="primary"
        block={true}
        htmlType="submit"
        id="submitButton"
      />
    </form>
  );
};
