// Copyright 2022, Imprivata, Inc.  All rights reserved.

import 'antd/dist/antd.css';
import { AuthnModule } from './authn-module';

import {
  getFactorTypes,
  subscribeToFactorChanged,
  subscribeToAuthnDataReady,
  passAuthnDataToAgent,
  setAuthenticationStatus,
} from './agent-api';
import { OuterDependencies } from './authn-module/store/types';
import { AuthenticationReadinessStatusCode } from './endpoint-agent-mock/types';
import { useSelector } from 'react-redux';
import { RootState } from './store/types';

const authnModuleDeps: OuterDependencies = {
  getFactorTypes,
  subscribeToFactorChanged,
  subscribeToAuthnDataReady,

  onSuccess: credentials => {
    passAuthnDataToAgent(credentials);
  },
  onReady: () => {
    setAuthenticationStatus(AuthenticationReadinessStatusCode.READY);
  },
  onNotReady: () => {
    setAuthenticationStatus(AuthenticationReadinessStatusCode.NOT_READY);
  },
};

export const App = () => {
  const { error, appAccessContext } = useSelector((state: RootState) => state);

  return (
    <AuthnModule
      error={error}
      appAccessContext={appAccessContext}
      dependencies={authnModuleDeps}
    />
  );
};
