// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Button, Input, Space, Typography } from 'antd';
import React from 'react';
import { AuthnMethod, AgentEvent } from '../../../types';
import { usePersistentSessionState } from '../../../hooks';

export const ProxReader: React.FC<{
  isConnected: boolean;
  onChange: () => void;
}> = props => {
  const [cardNumber, setCardNumber] = usePersistentSessionState({
    tu__card_number: '',
  });

  return (
    <Space
      data-testid="tu--prox-reader-section"
      direction="vertical"
      style={{ padding: 10 }}
    >
      <Space>
        <Typography.Title level={4}>ProxReader</Typography.Title>
        <Button
          data-testid="tu--prox-reader-connect-button"
          onClick={() => {
            if (props.isConnected) {
              window.impr._config.simulateDisconnectDevice(AuthnMethod.PROX);
            } else {
              window.impr._config.simulateConnectDevice(AuthnMethod.PROX);
            }
            props.onChange();
          }}
        >
          {props.isConnected ? 'Disconnect' : 'Connect'}
        </Button>
      </Space>
      <Space>
        <Button
          data-testid="tu--prox-reader-interact-button"
          disabled={!props.isConnected}
          onClick={() => {
            window.impr._config.simulateDeviceInteraction(
              AuthnMethod.PROX,
              AgentEvent.FACTOR_DATA_READY,
              {
                cardNumber:
                  (
                    document.querySelector(
                      'input#tu--card-number-input',
                    ) as HTMLInputElement
                  ).value || '',
              },
            );
          }}
        >
          Interact
        </Button>
        <Input
          disabled={!props.isConnected}
          data-testid="tu--card-number-input"
          id="tu--card-number-input"
          type="text"
          value={cardNumber.tu__card_number}
          onChange={e => {
            setCardNumber({
              tu__card_number: e.target.value,
            });
          }}
          placeholder="Prox number"
        />
      </Space>
    </Space>
  );
};
