// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnData, AuthnMethod } from '../../types';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';

const mockSystemData = {
  version: '1.0',
  osName: 'ToBeImplemented',
  osVersion: 'ToBeImplemented',
  computerName: 'ToBeImplemented',
};

const fallbackAuthnDataProps = {
  agentData: '',
  systemData: jsonToBase64(mockSystemData),
  deviceData: '',
  samlData: '',
};

// Function overloads.
// `prepareAuthnData` is used for all authn factors. It can receive
// Record<string, string>, but it's limited with overloads to accept
// only known factor properties to avoid typos and provide autocomplete
export function prepareAuthnData(
  authnMethod: AuthnMethod.USERNAME_PASSWORD,
  // username can be absent in payload when user is known from previous factors
  factorData: { version: string; username?: string; password: string },
): AuthnData;
export function prepareAuthnData(
  authnMethod: AuthnMethod.IMPR_ID,
  factorData: { version: string; totp: string },
): AuthnData;
export function prepareAuthnData(
  authnMethod: AuthnMethod.IMPR_ID,
  factorData: { version: string; samlData: string },
): AuthnData;

export function prepareAuthnData(
  factorType: AuthnMethod,
  factorData: Record<string, string>,
): AuthnData {
  return {
    ...fallbackAuthnDataProps,
    factorType,
    factorData: jsonToBase64(factorData),
  };
}
