// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { OrgPreferencesPayload } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { subscribeToAuthnDataReadyAction } from '../store/actions';
import proxCardImg from '../../../images/proxCardImg.svg';

export function ProxCardContainer(props: {
  orgPreferences?: OrgPreferencesPayload;
}): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToAuthnDataReadyAction());
  }, [dispatch]);

  const proxCardLabel = props.orgPreferences?.proxCardLabel
    ? t('login.proxcard.custom-label', {
        card: props.orgPreferences.proxCardLabel,
      })
    : t('login.proxcard.label');

  return (
    <div>
      <div className="formLabel subtitle" data-testid="prox-text-label">
        {proxCardLabel}
      </div>
      <img
        data-testid="prox-card-icon"
        src={proxCardImg}
        alt="prox-card-icon"
      />
      {
        // the link "Can't use proximity card" is temporarily out of scope,
        // it will be enabled again in the future
        // alternativeAuthnMethod?.factorType === AuthnMethod.USERNAME_PASSWORD && (
        // <> <br /> <br /> <br /> <a data-testid="cannot-use-prox-link" onClick={() => dispatch(switchAuthnMethod(alternativeAuthnMethod, false)) } > Can't use proximity card?
        // </a> </>)
      }
    </div>
  );
}
