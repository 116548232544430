// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { createAuthError, fatalError } from '../store/actions';
import {
  ErrorCodes,
  ErrorMessages,
  INVALID_SESSION_LOGOUT_CODES,
} from '../constants';

export const useQueryParams = (): URLSearchParams => {
  const location = useLocation();
  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
};

export const mapErrorToAction = (error: { code: string; message: string }) => {
  switch (true) {
    case INVALID_SESSION_LOGOUT_CODES.includes(error.code):
      return fatalError({
        errorMessage: ErrorMessages.SESSION_EXPIRED,
        retryConnection: true,
      });
    case error.code === ErrorCodes.ACCOUNT_DISABLED:
      return fatalError({
        errorMessage: ErrorMessages.ACCOUNT_DISABLED,
        retryConnection: true,
      });
    case error.code === ErrorCodes.CREDENTIAL_EXPIRED:
      return fatalError({
        errorMessage: ErrorMessages.PASSWORD_EXPIRED,
        retryConnection: true,
      });
    case error.code === ErrorCodes.AMBIGUOUS_USERNAME:
      return createAuthError({
        errorMessage: ErrorMessages.UXID_MULTIPLEUSERS,
      });
    default:
      return createAuthError({
        errorMessage: ErrorMessages.INCORRECT_PASSWORD,
      });
  }
};
