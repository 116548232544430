// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tracer } from '../tracing-package';
import getConfig from '../../appConfigUtils';
export * from './constants';
export type { RawSpanAttributes } from '../tracing-package';

const { ZIPKIN_TRACING_URL, OTLP_TRACING_URL, COLLECT_TRACING } = getConfig();

const queryParams = new URLSearchParams(window.location.search);
const journeyId = queryParams.get('journeyId');

export const tracer = new Tracer('authn-ui', journeyId, {
  zipkinExportUrl: ZIPKIN_TRACING_URL,
  otlpExportUrl: OTLP_TRACING_URL,
  collectSpans: COLLECT_TRACING === 'true',
});
