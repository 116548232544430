// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Alert } from 'antd';

export const TestUtilityMessage: React.FC<{
  onOpenTestUtility: () => void;
  onDisableTestUtility: () => void;
}> = props => {
  return (
    <Alert
      data-testid="tu--top-screen-warning"
      style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 3 }}
      type="warning"
      showIcon
      closable
      message={
        <span>
          The Authn UI is opened not in the Endpoint Agent, thus the Endpoint
          Agent is mocked.{' '}
          <a
            data-testid="tu--open-window-link"
            onClick={props.onOpenTestUtility}
          >
            Open mock test utility
          </a>
          . Click{' '}
          <a
            data-testid="tu--disable-test-utility"
            onClick={props.onDisableTestUtility}
          >
            here
          </a>{' '}
          to disable test utility for future runs until you clean local storage
          (you need to reload the page after disabling).
        </span>
      }
    />
  );
};
