// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Observable, share } from 'rxjs';
import {
  AuthnMethod,
  TraceContext,
  AgentEvent,
  FactorFilter,
} from '../authn-module/types';
import {
  AppAuthenticationCanceledEventHandler,
  AppAuthenticationStatusEventHandler,
  FactorDataReadyEventHandler,
  CloseWindowEventHandler,
  StatusData,
  FactorChangedEventHandler,
  AuthenticationReadinessStatusCode,
} from '../endpoint-agent-mock/types';
import { SpanNames, tracer } from '../authn-module/tracing';
import { log } from '../authn-module/utils';

export function subscribeToAuthnDataReady(
  callback: FactorDataReadyEventHandler,
) {
  subscribeToAgentEvent(
    AgentEvent.FACTOR_DATA_READY,
    { factorType: [AuthnMethod.PROX] },
    callback,
  );
}

export function subscribeToFactorChanged(callback: FactorChangedEventHandler) {
  subscribeToAgentEvent(
    AgentEvent.FACTOR_CHANGED,
    { factorType: [AuthnMethod.PROX] },
    callback,
  );
}

export function subscribeToAppAuthenticationStatus(
  callback: AppAuthenticationStatusEventHandler,
) {
  subscribeToAgentEvent(AgentEvent.APP_AUTHENTICATION_STATUS, null, callback);
}

export function subscribeToCloseWindow(callback: CloseWindowEventHandler) {
  subscribeToAgentEvent(AgentEvent.CLOSE_WINDOW, null, callback);
}

export function getAppAccessContext(
  journeyId: string,
  traceContext: TraceContext,
  callback: (appAccessContext: string) => void,
) {
  tracer.startSubspan(SpanNames.GET_APP_ACCESS_CONTEXT);
  log('call getAppAccessContext');
  window.impr.authn.getAppAccessContext(journeyId, traceContext, callback);
  tracer.endSpan(SpanNames.GET_APP_ACCESS_CONTEXT);
}

export const authenticationStatus$ = new Observable<[TraceContext, StatusData]>(
  observer => {
    const callback: AppAuthenticationStatusEventHandler = (_, data) => {
      observer.next([data.traceContext, data.statusData]);
    };
    subscribeToAgentEvent(AgentEvent.APP_AUTHENTICATION_STATUS, null, callback);
  },
).pipe(share());

export function endJourneyAndCloseWindow(
  journeyId: string,
  traceContext: TraceContext,
) {
  log('call endJourneyAndCloseWindow');
  window.impr.authn.endJourneyAndCloseWindow(journeyId, traceContext);
}

export function passAuthnDataToAgent(authnData: ImprCredential): void {
  tracer.startSubspan(SpanNames.SUBMIT_CREDENTIALS);
  log('call submitCredentials2');
  window.impr.authn.submitCredentials2(
    tracer.getJourneyId(),
    tracer.getTraceContext(),
    authnData,
  );
  tracer.endSpan(SpanNames.SUBMIT_CREDENTIALS);
}

export function getFactorTypes() {
  return new Promise<AuthnMethod[]>(resolve => {
    tracer.startSubspan(SpanNames.GET_DEVICE_FACTORS);
    log('pass callback to getDeviceFactorTypes');
    window.impr.authn.getDeviceFactorTypes(
      tracer.getTraceContext(),
      (agentFactors: AuthnMethod[]) => {
        tracer.endSpan(SpanNames.GET_DEVICE_FACTORS, { agentFactors });
        log(
          `execute callback for getDeviceFactorTypes (${JSON.stringify(
            agentFactors,
          )})`,
        );
        resolve(agentFactors);
      },
    );
  });
}

export function getAgentTraceContext(
  journeyId: string,
  callback: (traceContext: TraceContext) => void,
) {
  log(`pass callback to getTraceContext (journeyId: ${journeyId})`);
  window.impr.otel.getTraceContext(journeyId, (...args) => {
    log('execute callback for getTraceContext');
    callback(...args);
  });
}

export function setAuthenticationStatus(
  statusCode: AuthenticationReadinessStatusCode,
) {
  const journeyId = tracer.getJourneyId();
  log(
    `call setAuthenticationStatus with (${statusCode}) (journeyId: ${journeyId})`,
  );
  window.impr.authn.setAuthenticationStatus(
    journeyId,
    tracer.getTraceContext(),
    statusCode,
  );
}

export function showWindow(show: boolean) {
  const journeyId = tracer.getJourneyId();
  log(`call showWindow (${show}) (journeyId: ${journeyId})`);
  window.impr.window.showWindow(journeyId, tracer.getTraceContext(), show);
}

type SubscriptionArguments =
  | [
      AgentEvent.APP_AUTHENTICATION_STATUS,
      FactorFilter,
      AppAuthenticationStatusEventHandler,
    ]
  | [
      AgentEvent.APP_AUTHENTICATION_CANCELED,
      FactorFilter,
      AppAuthenticationCanceledEventHandler,
    ]
  | [AgentEvent.FACTOR_DATA_READY, FactorFilter, FactorDataReadyEventHandler]
  | [AgentEvent.CLOSE_WINDOW, FactorFilter, CloseWindowEventHandler]
  | [AgentEvent.FACTOR_CHANGED, FactorFilter, FactorChangedEventHandler];

export function subscribeToAgentEvent(
  ...[event, filter, callback]: SubscriptionArguments
): void {
  log(`subscribe to (${event})`);
  window.impr.event.on2(
    [event],
    tracer.getJourneyId(),
    filter,
    // eslint-disable-next-line
    // @ts-ignore
    (...args) => {
      log(`execute callback for event (${event}) (args: ${args})`);
      // eslint-disable-next-line
      // @ts-ignore
      callback(...args);
    },
  );
}
