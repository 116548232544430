// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const Messages = {
  IMAGE_ALT: 'No Authentication Factors Available' as const,
  SOMETHING_UNEXPECTED: `An unexpected problem occurred.` as const,
};

export const References = {
  LINK_URL: 'https://imprivata.status.io' as const,
  LINK_TEXT: 'imprivata.status.io' as const,
};
