// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AgentApi } from './types';
import {
  MemorySection,
  EventSection,
  AuthnSection,
  ConfigSection,
  OtelSection,
  WindowSection,
} from './sections';

export class EndpointAgentMock implements AgentApi {
  _memory = new MemorySection();
  _config = new ConfigSection(this._memory);

  otel = new OtelSection();
  window = new WindowSection(this._memory);
  event = new EventSection(this._memory);
  authn = new AuthnSection(this._memory);

  constructor() {
    const storedAppContext = sessionStorage.getItem('app_access_context');
    if (storedAppContext) {
      this._config.setAppAccessContext(storedAppContext);
    }
  }
}
