// Copyright 2022, Imprivata, Inc.  All rights reserved.

import ReactDOM from 'react-dom';
import { TestUtility } from './TestUtility';

export interface ITestUtility {
  tapProximityCard: () => void;
}

export function attachTestUtility(): void {
  const container = createAndRenderContainer();

  ReactDOM.render(<TestUtility />, container);
}

function createAndRenderContainer() {
  const container = document.createElement('div');
  container.id = 'test-utility-container';
  document.body.append(container);

  return container;
}
