// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { ErrorCodes, ErrorMessages } from '../pages/login/constants';
import { fatalError } from '../pages/login/store/actions';
import { AppDispatch } from '../pages/login/store/types';
import { createContextPropagationInterceptor } from '../tracing-package';
import { deleteHeader } from './api';
import { Headers } from './constants';
import { tracer } from '../tracing';
import {
  logHttpRequest,
  logHttpSuccessResponse,
  logHttpErrorResponse,
} from '../utils';

export const errorConvertingInterceptor = (
  config: AxiosResponse,
): unknown | Promise<unknown> => {
  if (config.data.error) {
    if (
      config.data.error.code === ErrorCodes.AUTHENTICATOR_NOT_ENROLLED ||
      config.data.error.code === ErrorCodes.ACCOUNT_DISABLED ||
      config.data.error.code === ErrorCodes.CREDENTIAL_EXPIRED
    ) {
      deleteHeader(Headers.ImprSessionId);
    }
    return Promise.reject(config.data.error);
  } else {
    return config.data;
  }
};

export const fatalErrorInterceptor =
  (dispatch: AppDispatch) =>
  (err: AxiosError): Promise<AxiosError> | undefined => {
    deleteHeader(Headers.ImprSessionId);
    if (err.response && err.response?.status === 400) {
      return Promise.reject(err.response.data);
    }
    if (err.response && err.response?.status === 401) {
      return Promise.reject(err.response.data);
    }
    if (!err.response || err.response?.status >= 500) {
      // special case for the very first request to avoid infinite loop
      if (
        err.config.url?.includes('/authn-web/authn/v1/factor-options/get') ||
        err.config.url?.includes('/authn-web/authn/v2/next-factors/get')
      ) {
        dispatch(
          fatalError({
            errorMessage: ErrorMessages.FATAL_ERROR,
            retryConnection: false,
          }),
        );
      } else {
        dispatch(
          fatalError({
            errorMessage: ErrorMessages.FATAL_ERROR,
            retryConnection: true,
          }),
        );
      }
    }
    // returning undefined as an indicator that error happened but was already handled
  };

export const requestLoggingInterceptor = (
  config: AxiosRequestConfig,
): AxiosRequestConfig => {
  logHttpRequest(config);

  return config;
};

export const successResponseLoggingInterceptor = (
  response: AxiosResponse,
): AxiosResponse => {
  logHttpSuccessResponse(response);

  return response;
};

export const failResponseLoggingInterceptor = (
  error: AxiosError,
): Promise<AxiosError> => {
  logHttpErrorResponse(error);

  return Promise.reject(error);
};

let requestInterceptors: number[] = [];
let responseInterceptors: number[] = [];

export const applyInterceptors = (dispatch: AppDispatch): void => {
  requestInterceptors.push(
    axios.interceptors.request.use(requestLoggingInterceptor),
  );
  requestInterceptors.push(
    axios.interceptors.request.use(createContextPropagationInterceptor(tracer)),
  );

  responseInterceptors.push(
    axios.interceptors.response.use(
      successResponseLoggingInterceptor,
      failResponseLoggingInterceptor,
    ),
  );
  responseInterceptors.push(
    axios.interceptors.response.use(
      errorConvertingInterceptor,
      fatalErrorInterceptor(dispatch),
    ),
  );
};

export const ejectInterceptors = (): void => {
  requestInterceptors.forEach(interceptor =>
    axios.interceptors.request.eject(interceptor),
  );

  responseInterceptors.forEach(interceptor =>
    axios.interceptors.response.eject(interceptor),
  );

  requestInterceptors = [];
  responseInterceptors = [];
};
