// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { References } from './constants';
import { useTranslation } from 'react-i18next';

export const FactorError = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div id="error-container" data-testid="error-container">
      <div id="error-message">{t('errors.SOMETHING_UNEXPECTED')}</div>
      <a
        style={{ visibility: 'hidden' }}
        href={References.LINK_URL}
        title={References.LINK_TEXT}
      >
        {References.LINK_TEXT}
      </a>
    </div>
  );
};
