// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Alert, Space, Input, Button, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AuthnMethod } from '../../types';
import { ProxReader } from './devices';
import { makeElementDragable } from './utils';
import { usePersistentSessionState, usePersistentState } from '../../hooks';
import { AuthenticationStatusSection, AppContextSection } from './sections';

export const TestUtilityWindow: React.FC<{ onClose?: () => void }> = props => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const connectedDevicesAtStartup = useMemo(() => {
    return window.impr._config.getConnectedDevices();
  }, []);

  const [connectedDevices, setConnectedDevices] = useState<AuthnMethod[]>(
    connectedDevicesAtStartup,
  );

  const [coords, setCoords] = usePersistentSessionState({
    tu__offset_top: 40,
    tu__offset_left: 40,
  });

  const [paramsValues, setParamsValues] = usePersistentState({
    tu__context_type: 'clinical-app-login',
    tu__resource_type: 'epic-hyperdrive',
    tu__tenant_id: '',
  });

  const onDeviceConnectionChange = () => {
    setConnectedDevices(window.impr._config.getConnectedDevices());
  };

  useEffect(() => {
    if (containerRef.current && headerRef.current) {
      makeElementDragable(containerRef.current, headerRef.current, args => {
        setCoords({
          tu__offset_top: args.offSetTop > 0 ? args.offSetTop : 0,
          tu__offset_left: args.offSetLeft,
        });
      });
    }
  }, [containerRef, headerRef, setCoords]);

  return (
    <div
      data-testid="tu--window"
      ref={containerRef}
      style={{
        zIndex: 999,
        position: 'fixed',
        top: coords.tu__offset_top,
        left: coords.tu__offset_left,
        backgroundColor: 'white',
        border: '1px solid black',
      }}
    >
      <div data-testid="tu--window-header" ref={headerRef}>
        <Alert
          onClose={props.onClose}
          message="Test Utility"
          type="info"
          closable
        />
      </div>
      <Space direction="vertical" style={{ padding: 10 }}>
        <Typography.Title level={4}>Open UI</Typography.Title>
        <Input
          value={paramsValues.tu__context_type}
          onChange={e => {
            setParamsValues({
              ...paramsValues,
              tu__context_type: e.target.value,
            });
          }}
          type="text"
          addonBefore="context-type"
          data-testid="tu--context-type-input"
        />
        <Input
          value={paramsValues.tu__resource_type}
          onChange={e => {
            setParamsValues({
              ...paramsValues,
              tu__resource_type: e.target.value,
            });
          }}
          type="text"
          addonBefore="resource-type"
          data-testid="tu--resource-type-input"
        />
        <Input
          value={paramsValues.tu__tenant_id}
          onChange={e => {
            setParamsValues({
              ...paramsValues,
              tu__tenant_id: e.target.value,
            });
          }}
          type="text"
          addonBefore="tenant id"
          data-testid="tu--tenant-id-input"
        />
        <Button
          data-testid="tu--open-ui-button"
          onClick={() => {
            window.impr._config.openUi({
              contextType: paramsValues.tu__context_type,
              resourceType: paramsValues.tu__resource_type,
              tenantId: paramsValues.tu__tenant_id,
            });
          }}
        >
          Open UI
        </Button>
      </Space>
      <hr />
      <Space direction="vertical" style={{ padding: 10 }}>
        <Typography.Title level={4}>Close UI</Typography.Title>
        <Button
          data-testid="tu--close-ui-button"
          onClick={() => {
            window.impr._config.simulateCloseWindow();
          }}
        >
          Initiate close UI
        </Button>
      </Space>
      <hr />
      <ProxReader
        isConnected={connectedDevices.includes(AuthnMethod.PROX)}
        onChange={onDeviceConnectionChange}
      />
      <hr />
      <AuthenticationStatusSection />
      <hr />
      <AppContextSection />
    </div>
  );
};
