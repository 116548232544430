// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { MemorySection } from '.';
import { TraceContext } from '../types';
import { tracer } from '../tracing';

export class WindowSection {
  constructor(private readonly _memory: MemorySection) {}

  showWindow(_: string, traceContext: TraceContext, showWindow: boolean): void {
    tracer.startSpanFromContext('show_window', traceContext);
    this._memory.isWindowShown = showWindow;
    if (this._memory.onShowWindowChangeCallback) {
      this._memory.onShowWindowChangeCallback(this._memory.isWindowShown);
    }
    tracer.endSpan('show_window');
  }
}
