// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useState, useCallback, useEffect } from 'react';
import { AuthnMethod, OrgPreferencesPayload } from '../../../types';
import { UserNameForm, PasswordForm } from '../../../components/login';
import { clearErrors, authenticateAction } from '../store/actions';
import { prepareAuthnData } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginState } from '../store/types';

export function UsernamePasswordContainer(props: {
  orgPreferences?: OrgPreferencesPayload;
}): JSX.Element {
  const dispatch = useDispatch();
  const state = useSelector((rootState: ILoginState) => rootState);

  const { isLoading, tenantIdError, authError, userKnown, fullUsername } =
    state;

  const [username, setUsername] = useState(fullUsername || '');
  const [password, setPassword] = useState('');
  const [usernameChecked, setUsernameChecked] = useState(userKnown);

  useEffect(() => {
    if (authError) {
      setUsername('');
      setPassword('');
    }
  }, [authError]);

  const userNameStep = !tenantIdError && !usernameChecked;
  const passwordStep = usernameChecked;

  const handleUserName = (name: string): void => {
    setUsernameChecked(true);
    setUsername(name);
    dispatch(clearErrors());
  };

  const handleUserAuthenticate = useCallback(
    (userPass: string, userNameToUse: string = username) => {
      setPassword(userPass);

      dispatch(
        authenticateAction.request({
          authnData: prepareAuthnData(AuthnMethod.USERNAME_PASSWORD, {
            version: '1.0',
            // in some cases username is empty. If so, it should not be sent.
            ...(userNameToUse ? { username: userNameToUse } : {}),
            password: userPass,
          }),
        }),
      );
    },
    [setPassword, dispatch, username],
  );

  const getComponentsToRender = () => {
    if (userNameStep) {
      return (
        <>
          <UserNameForm
            initValue={username}
            disabled={isLoading}
            error={''}
            onSubmit={handleUserName}
            userNameLabel={props.orgPreferences?.userNameLabel}
          />
          {
            // the link "Use proximity card" is temporarily out of scope,
            // it will be enabled again in the future
            // alternativeAuthnMethod?.factorType === AuthnMethod.PROX && !usernameChecked && ( <a data-testid="use-proximity-card-link" onClick={() => {
            // dispatch(switchAuthnMethod(alternativeAuthnMethod, false)); }} > <br /> <br /> Use proximity card </a>)
          }
        </>
      );
    } else if (passwordStep) {
      return (
        <PasswordForm
          disabled={isLoading}
          error={''}
          onSubmit={handleUserAuthenticate}
          initValue={password}
          passwordLabel={props.orgPreferences?.passwordLabel}
        />
      );
    }
    return null;
  };

  return (
    <div className="usernamePasswordContainer">{getComponentsToRender()}</div>
  );
}
