// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod, AgentEvent } from './types';
import { EndpointAgentMock } from './EndpointAgentMock';
import { attachTestUtility } from './test-utility';
import { journeyId } from './tracing';
import { shouldMockEndpointAgent } from './utils';

export function attachAgentMock(): void {
  const agentMock = new EndpointAgentMock();
  window.impr = agentMock;
  agentMock._config.simulateConnectDevice(AuthnMethod.PROX);
  // eslint-disable-next-line
  // @ts-ignore
  window.tapProximityCard = () => {
    agentMock._config.simulateDeviceInteraction(
      AuthnMethod.PROX,
      AgentEvent.FACTOR_DATA_READY,
      { cardNumber: '000001' },
    );
  };

  // eslint-disable-next-line
  // @ts-ignore
  window.tapUnenrolledCard = () => {
    agentMock._config.simulateDeviceInteraction(
      AuthnMethod.PROX,
      AgentEvent.FACTOR_DATA_READY,
      { cardNumber: '999999' },
    );
  };
  // eslint-disable-next-line
  // @ts-ignore
  window.connectReader = () => {
    agentMock._config.simulateConnectDevice(AuthnMethod.PROX);
  };
  // eslint-disable-next-line
  // @ts-ignore
  window.disconnectReader = () => {
    agentMock._config.simulateDisconnectDevice(AuthnMethod.PROX);
  };

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has('journeyId')) {
    const storedJourneyId = sessionStorage.getItem('tu__journey_id');
    if (storedJourneyId !== queryParams.get('journeyId')) {
      queryParams.set('journeyId', journeyId);
      window.location.search = queryParams.toString();
      sessionStorage.setItem('tu__journey_id', journeyId);
    } else {
      sessionStorage.removeItem('tu__journey_id');
    }
  }

  attachTestUtility();
}

export const attachAgentMockIfNeeded = () => {
  if (shouldMockEndpointAgent()) {
    attachAgentMock();
  }
};
