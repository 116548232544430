// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, switchMap, of } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { readAppAccessContext, saveAppAccessContext } from '../actions';
import { Action, RootState, EpicDependencies } from '../types';
import { AppAccessContext } from '../../authn-module/types';
import { tracer } from '../../authn-module/tracing';
import { base64ToJson } from '../../authn-module/utils';

export const readAppAccessContextEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<EpicDependencies, 'getAppAccessContext$'>
> = (action$, _, dependencies) => {
  const { getAppAccessContext$ } = dependencies;

  return action$.pipe(
    filter(isActionOf(readAppAccessContext)),
    switchMap(() => {
      return getAppAccessContext$(
        tracer.getJourneyId(),
        tracer.getTraceContext(),
      ).pipe(
        switchMap(appAccessContext => {
          const decoded: AppAccessContext = appAccessContext
            ? base64ToJson(appAccessContext)
            : ({} as AppAccessContext);

          return of(saveAppAccessContext(decoded));
        }),
      );
    }),
  );
};
