// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { TestUtilityWindow } from './window';
import { TestUtilityMessage } from './top-screen-warning';
import { usePersistentSessionState } from '../hooks';
import { DISABLE_EA_MOCK } from '../constants';

export const TestUtility: React.FC = () => {
  const [state, setState] = usePersistentSessionState<{
    tu__window_opened: boolean;
  }>({ tu__window_opened: false });
  const [showWindow, setShowWindow] = useState(
    window.impr._memory.isWindowShown,
  );

  useEffect(() => {
    window.impr._config.onShowWindowChange(isWindowShown =>
      setShowWindow(isWindowShown),
    );
  }, []);

  return (
    <>
      <TestUtilityMessage
        onOpenTestUtility={() => setState({ tu__window_opened: true })}
        onDisableTestUtility={() => {
          localStorage.setItem(DISABLE_EA_MOCK, 'true');
        }}
      />
      {showWindow ? (
        <div data-testid="window-is-shown--marker" />
      ) : (
        <div
          data-testid="window-is-hidden--overlay"
          style={{
            position: 'fixed',
            top: 0,
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 32,
            color: 'white',
            zIndex: 2,
          }}
        >
          Window is hidden
        </div>
      )}
      {state.tu__window_opened && (
        <TestUtilityWindow
          onClose={() => setState({ tu__window_opened: false })}
        />
      )}
    </>
  );
};
