// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, Epic } from 'redux-observable';
import { filter, switchMap, EMPTY } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { saveFactorOptions } from '../actions';
import { Action } from '../rootAction';
import { initEpic } from './initEpic';
import { authenticateEpic } from './authenticateEpic';
import { sessionsEpic } from './session';
import { ILoginState } from '../types';
import { EpicDependencies } from '../../../../store/types';
import { enrollmentEpic } from './enrollmentEpic';
import { fetchFactorOptionsEpic } from './factorOptionsEpic';
import { factorChangeEpic } from './factorChangeEpic';
import { authnDataReadyEpic } from './authnDataReadyEpic';
import { pushAcceptedEpic } from './pushAcceptedEpic';

export const authenticationStatusEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'onReady' | 'onNotReady'>
> = (action$, _, dependencies) => {
  const { onReady, onNotReady } = dependencies;

  return action$.pipe(
    filter(isActionOf(saveFactorOptions)),
    switchMap(action => {
      // don't call onReady/onNotReady multiple times in a row
      if (
        action.payload.authnMethods.length &&
        // The issue is that Authn UI calls onReady during inline enrollment.
        // At the moment of implementation it's not possible to know whether saveFactorOptions
        // was dispatched during inline-enrollment because of ME-3251, when it's fixed the enrollment
        // status can be taken from Redux state
        getIsReady() !== true
      ) {
        setIsReady(true);
        onReady();
      } else if (
        action.payload.authnMethods.length === 0 &&
        getIsReady() !== false
      ) {
        setIsReady(false);
        onNotReady();
      }

      return EMPTY;
    }),
  );
};

// TODO: GLOBAL STATE!!!
// should be fixed after ME-3251
let _isReady: boolean | undefined;
function getIsReady() {
  return _isReady;
}
function setIsReady(status: boolean) {
  _isReady = status;
}

export const loginEpic = combineEpics<
  Action,
  Action,
  ILoginState,
  EpicDependencies
>(
  fetchFactorOptionsEpic,
  sessionsEpic,
  authenticateEpic,
  enrollmentEpic,
  initEpic,
  authenticationStatusEpic,
  factorChangeEpic,
  authnDataReadyEpic,
  pushAcceptedEpic,
);
