// Copyright 2022, Imprivata, Inc.  All rights reserved.

import getConfig from '../appConfigUtils';
import { Tracer } from '../authn-module/tracing-package';
import short from 'short-uuid';

const { ZIPKIN_TRACING_URL, OTLP_TRACING_URL } = getConfig();

export const journeyId = short.generate() + 'mock';

export const tracer = new Tracer('endpoint-agent-mock', journeyId, {
  zipkinExportUrl: ZIPKIN_TRACING_URL,
  otlpExportUrl: OTLP_TRACING_URL,
});
