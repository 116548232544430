// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers, Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AppAccessContext } from '../authn-module/types';
import { errorAction, saveAppAccessContext } from './actions';
import { RootState, Action } from './types';

export const initialState: RootState = {
  error: false,
  appAccessContext: null,
};

const reducer: Reducer<RootState, Action> = combineReducers<RootState, Action>({
  error: createReducer(initialState.error).handleAction(
    errorAction,
    () => true,
  ),
  appAccessContext: createReducer(initialState.appAccessContext).handleAction(
    saveAppAccessContext,
    (_: RootState, { payload }: { payload: AppAccessContext }) => payload,
  ),
});

export default reducer;
