// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod, AgentEvent, AuthenticationStatusCode } from '../types';
import { encodeObjectProperties } from '../../authn-module/utils/base64';
import { ProxReader } from '../devices';
import { MemorySection } from './MemorySection';
import { journeyId, tracer } from '../tracing';
import {
  agentData,
  deviceData,
  systemData,
  TEST_SAML_STRING,
} from '../staticData';

export class ConfigSection {
  constructor(private readonly _memory: MemorySection) {}
  simulateConnectDevice(deviceType: AuthnMethod): void {
    switch (deviceType) {
      case AuthnMethod.PROX:
        this._memory.connectedDevices.set(AuthnMethod.PROX, new ProxReader());
        break;
      default:
        throw new Error(`Unsupported deviceType: ${deviceType}`);
    }

    const eventName = AgentEvent.FACTOR_CHANGED;
    const callback = this._memory.registeredCallbacks[eventName];
    if (callback) {
      const factorList = Array.from(this._memory.connectedDevices.keys());
      tracer.startSpan('device_connected', {
        devices: factorList,
        newDevice: deviceType,
      });
      callback(eventName, {
        traceContext: tracer.getTraceContext(),
        factorList,
      });
      tracer.endSpan('device_connected');
    }
  }

  simulateDisconnectDevice(deviceType: AuthnMethod) {
    if (this._memory.connectedDevices.has(deviceType)) {
      this._memory.connectedDevices.delete(deviceType);

      const eventName = AgentEvent.FACTOR_CHANGED;
      const callback = this._memory.registeredCallbacks[eventName];
      if (callback) {
        const factorList = Array.from(this._memory.connectedDevices.keys());
        tracer.startSpan('device_disconnected', {
          devices: factorList,
          disconnectedDevice: deviceType,
        });
        callback(eventName, {
          traceContext: tracer.getTraceContext(),
          factorList,
        });
        tracer.endSpan('device_disconnected');
      }
    }
  }

  simulateDeviceInteraction(
    deviceType: AuthnMethod,
    eventType: AgentEvent,
    options: Record<string, string>,
  ) {
    const device = this._memory.connectedDevices.get(deviceType);

    if (device) {
      const [traceContext, deviceResult] = device.simulateEvent(
        eventType,
        options || {},
      );

      if (eventType === AgentEvent.FACTOR_DATA_READY) {
        const callback = this._memory.registeredCallbacks[eventType];
        if (callback) {
          const authnData = {
            ...encodeObjectProperties({
              agentData,
              deviceData,
              systemData,
              ...deviceResult,
            }),
            samlData: TEST_SAML_STRING,
            factorType: deviceType,
          };
          callback(eventType, { traceContext, authnData });
        }
      }
    }
  }

  simulateCloseWindow(): void {
    const eventName = AgentEvent.CLOSE_WINDOW;
    const callback = this._memory.registeredCallbacks[eventName];
    if (callback) {
      tracer.startSpan('initiate_close_agent');
      callback(eventName, { traceContext: tracer.getTraceContext() });
    }
  }

  sendAuthenticationStatus(statusCode: AuthenticationStatusCode) {
    const eventName = AgentEvent.APP_AUTHENTICATION_STATUS;
    const callback = this._memory.registeredCallbacks[eventName];
    if (callback) {
      tracer.startSpan('app_authnentication_status');
      callback(eventName, {
        traceContext: tracer.getTraceContext(),
        statusData: { statusCode },
      });
      tracer.endSpan('app_authnentication_status');
    }
  }

  setAppAccessContext(appAccessContext: string) {
    this._memory.appAccessContext = appAccessContext;
  }

  getConnectedDevices(): AuthnMethod[] {
    return Array.from(this._memory.connectedDevices.keys());
  }

  getSubmittedCredentials(): ImprCredential[] {
    return this._memory.submittedCredentials;
  }

  onShowWindowChange(callback: (isWindowShown: boolean) => void): void {
    this._memory.onShowWindowChangeCallback = callback;
  }

  openUi({
    contextType,
    resourceType,
    tenantId,
  }: {
    contextType: string;
    resourceType: string;
    tenantId: string;
  }) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('contextType', contextType);
    queryParams.set('resourceType', resourceType);
    queryParams.set('tenantId', tenantId);

    sessionStorage.setItem('tu__journey_id', journeyId);
    queryParams.set('journeyId', journeyId);

    window.location.search = queryParams.toString();
  }
}
