// Copyright 2022, Imprivata, Inc.  All rights reserved.

import getConfig from '../../appConfigUtils';
import { DISABLE_EA_MOCK } from '../constants';

export const shouldMockEndpointAgent = (): boolean => {
  return (
    // Endpoint Agent API is not defined
    typeof window.impr !== 'object' &&
    // Test Utility is not disabled in localStarage
    localStorage.getItem(DISABLE_EA_MOCK) !== 'true' &&
    // Test Utility is enabled in appConfig
    getConfig().TEST_UTILITY_ENABLED === 'true'
  );
};
