// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, InputBox } from '@imprivata-cloud/astra-ui-component-library';
import { useTranslation } from 'react-i18next';
import usernameImg from '../../../images/usernameImg.svg';

interface IProps {
  initValue?: string;
  error?: string;
  disabled?: boolean;
  userNameLabel?: string;
  onSubmit: (username: string) => void;
}

export const UserNameForm = ({
  initValue = '',
  error = '',
  disabled = false,
  onSubmit,
  userNameLabel = '',
}: IProps): JSX.Element => {
  const [username, setUsername] = useState(initValue);
  const [errorMsg, setErrorMsg] = useState(error);
  const { t } = useTranslation();

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value.trim());
    if (errorMsg) {
      setErrorMsg('');
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(username);
  };

  const finalizedLabel = userNameLabel
    ? t('login.username.custom-label', {
        username: userNameLabel,
        // Prevent HTML Entities from being screened twice - by i18n function and then by react
        interpolation: { escapeValue: false },
      })
    : t('login.username.label');

  return (
    <form onSubmit={handleSubmit}>
      <label
        htmlFor="usernameInput"
        data-testid="username-input-label"
        className="formLabel subtitle"
      >
        {finalizedLabel}
      </label>

      <img
        src={usernameImg}
        alt="usernameImg"
        style={{ marginBottom: '36px' }}
      />
      <InputBox
        type="text"
        placeholder={''}
        value={username}
        data-testid={'username-input'}
        onChange={handleChange}
        error={errorMsg}
        autoFocus={!disabled}
        disabled={disabled}
        width="100%"
        size="large"
        className="primary-body"
        id="usernameInput"
      />
      <Button
        astraButtonType={['major']}
        label={t('actions.continue')}
        disabled={!username.length || disabled}
        data-testid="submit-button"
        type="primary"
        block={true}
        htmlType="submit"
        id="submitButton"
      />
    </form>
  );
};
