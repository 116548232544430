// Copyright 2022, Imprivata, Inc.  All rights reserved.

import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './pages/login';
import imprivataGraphic from './images/imprivata.svg';
import hospitalLogo from './images/imprivata-logo.png';
import { AuthBanner } from '@imprivata-cloud/astra-ui-component-library';
import { getOrgPreferences } from './api/api';
import { ILoginState } from './pages/login/store/types';
import { OrgPreferencesPayload } from './types';
import { clearErrors, startAppInit } from './pages/login/store/actions';
import { SpanNames, tracer } from './tracing';
import './index.css';

export interface IAppContext {
  showLogo?: boolean;
  setShowLogo?: React.Dispatch<React.SetStateAction<boolean>>;
  orgPreferences?: OrgPreferencesPayload;
}

export const AppContext = createContext<IAppContext>({});

function App(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startAppInit());
  }, [dispatch]);

  const bannerMsgs = useSelector((state: ILoginState) => state.bannerMsgs);
  const [showLogo, setShowLogo] = useState(false);
  const [orgPreferencesLoaded, setOrgPreferencesLoaded] = useState(false);
  const [contextValue, setContextValue] = useState<IAppContext>({
    showLogo,
    setShowLogo,
  });
  const [logoToUse, setLogoToUse] = useState(hospitalLogo);
  const { isEnrollmentCeremony } = useSelector(s => s) as ILoginState;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('tenantId')) {
      const tenantId: string = queryParams.get('tenantId') as string;

      if (!contextValue.orgPreferences) {
        tracer.startSpan(SpanNames.GET_ORG_PREFERENCES);
        getOrgPreferences(tenantId)
          .then(response => {
            tracer.endSpan(SpanNames.GET_ORG_PREFERENCES, { response });

            setContextValue({
              showLogo,
              setShowLogo,
              orgPreferences: response,
            });
            setOrgPreferencesLoaded(true);
            if (response.logoUrl) {
              setLogoToUse(response.logoUrl);
            }
          })
          .catch(error => {
            tracer.endSpan(SpanNames.GET_ORG_PREFERENCES, { error });

            setOrgPreferencesLoaded(true);
          });
      } else {
        setContextValue({
          showLogo,
          setShowLogo,
          orgPreferences: contextValue.orgPreferences,
        });
        setOrgPreferencesLoaded(true);
      }
    } else {
      setOrgPreferencesLoaded(true);
    }
  }, [showLogo, setShowLogo, contextValue.orgPreferences]);

  const displayPage =
    orgPreferencesLoaded || (bannerMsgs && bannerMsgs.length > 0)
      ? { display: 'flex' }
      : { display: 'none' };
  const clearError = () => dispatch(clearErrors());
  return (
    <AppContext.Provider value={contextValue}>
      <div className="container-wrapper" style={displayPage}>
        <div className="container">
          <div className={`card ${isEnrollmentCeremony ? 'cardEnroll' : ''}`}>
            {bannerMsgs &&
              bannerMsgs.length > 0 &&
              bannerMsgs.map((banner, idx) => {
                return (
                  <AuthBanner
                    key={idx}
                    type={banner.type}
                    primary={banner.primary}
                    secondary={banner.secondary}
                    size={banner.size}
                    onClose={clearError}
                  />
                );
              })}
            {!isEnrollmentCeremony && orgPreferencesLoaded && (
              <header className="cardHeader">
                {showLogo && (
                  <div className="hospitalLogoContainer">
                    <div className="hospitalLogo" data-testid="logo">
                      <img src={logoToUse} alt="Hospital logo" />
                    </div>
                  </div>
                )}
              </header>
            )}

            <main className="cardBody">
              <BrowserRouter>
                <Switch>
                  <Route path="/" component={LoginPage} />
                </Switch>
              </BrowserRouter>
            </main>
            <footer>
              <img src={imprivataGraphic} alt="Imprivata" />
            </footer>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
