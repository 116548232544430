// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, switchMap, from, of, catchError, tap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { AuthnMethod } from '../../../../../types';
import { EpicDependencies } from '../../../../../store/types';
import { SpanNames, tracer } from '../../../../../tracing';
import {
  ErrorMessages,
  INVALID_SESSION_LOGOUT_CODES,
} from '../../../constants';
import {
  createAuthError,
  createEnrollmentSession,
  fatalError,
  stopLoading,
} from '../../actions';
import { Action } from '../../rootAction';
import { ILoginState } from '../../types';

export const enrollmentSessionEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'getFactorTypes' | 'createSession'>
> = (action$, _, deps) => {
  return action$.pipe(
    filter(isActionOf(createEnrollmentSession.request)),
    switchMap(action => {
      const dispatchAfter = action?.payload?.dispatchAfter;

      return from(deps.getFactorTypes()).pipe(
        switchMap(agentFactors => {
          const queryParams = new URLSearchParams(window.location.search);
          const contextType = queryParams.get('contextType') || '';
          const resourceType = queryParams.get('resourceType') || '';
          const methods = [
            ...agentFactors,
            AuthnMethod.USERNAME_PASSWORD,
            AuthnMethod.IMPR_ID,
          ];

          tracer.startSpan(SpanNames.CREATE_ENROLLMENT_SESSION);
          return from(
            deps.createSession(
              methods,
              { contextType, resourceType, samlData: '' },
              true,
            ),
          ).pipe(
            tap(() => tracer.endSpan(SpanNames.CREATE_ENROLLMENT_SESSION)),
          );
        }),
        switchMap(() => {
          const actions: Action[] = [createEnrollmentSession.success()];

          if (dispatchAfter) {
            actions.push(dispatchAfter as Action);
          }

          return from(actions);
        }),
        catchError(error => {
          tracer.endSpan(SpanNames.CREATE_ENROLLMENT_SESSION, { error });

          if (INVALID_SESSION_LOGOUT_CODES.includes(error.code)) {
            return of(
              fatalError({
                errorMessage: ErrorMessages.SESSION_EXPIRED,
                retryConnection: true,
              }),
              stopLoading(),
            );
          } else {
            return of(
              createAuthError({
                errorMessage: ErrorMessages.INCORRECT_TENANT_ID,
              }),
              stopLoading(),
            );
          }
        }),
      );
    }),
  );
};
