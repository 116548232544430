// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { ILoginState } from './types';
import { Action } from './rootAction';
import {
  START_LOADING,
  STOP_LOADING,
  FATAL_ERROR,
  SAVE_DEVICE_AUTHN_METHODS,
  SWITCH_AUTHN_METHOD,
  SAVE_FACTOR_OPTIONS,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_ERROR,
  CREATE_AUTH_ERROR,
  CLEAR_SESSION,
  AUTHENTICATE_SUCCESS,
  CLEAR_ERRORS,
  START_ENROLLMENT_CEREMONY,
  SAVE_AUTHN_DATA,
  START_RESET,
  STOP_RESET,
  PUSH_NOTIFICATION_EXPIRED_ERROR,
  PUSH_NOTIFICATION_ACCEPTED_SUCCESS,
} from './constants';
import {
  clearErrorBanners,
  createEnrollmentSession,
  createAuthenticationSession,
  setBanners,
  setModelData,
  saveUsername,
  authenticateAction,
} from './actions';
import { ErrorMessages } from '../constants';
import { AuthBannerType } from '@imprivata-cloud/astra-ui-component-library';

export const initialState: ILoginState = {
  isInitialState: true,
  tenantId: '',
  tenantIdError: '',
  tenantIdChecked: false,
  sessionId: '',
  enrollmentSessionCreated: false,
  authenticationSessionCreated: false,
  isLoading: true,
  pushNotificationExpiredError: '',
  isStartGetFactorFlow: false,
  userKnown: false,
  userId: '',
  fullUsername: '',
  authnToken: '',

  success: false,
  authnDeviceMethods: [],
  authnMethods: [],

  isEnrollmentCeremony: false,

  authnData: undefined,
  bannerMsgs: [],
};

export const initialSessionId = 'session-started';

const reducer: Reducer<ILoginState, Action> = (state, action) => {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case START_LOADING: {
      return { ...state, isLoading: true };
    }
    case STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    case START_RESET: {
      return {
        ...initialState,
        bannerMsgs: action.payload?.banners || initialState.bannerMsgs,
      };
    }
    case STOP_RESET: {
      return {
        ...state,
        isInitialState: false,
      };
    }
    case FATAL_ERROR: {
      return {
        ...initialState,
        isInitialState: false,
        isLoading: false,
        fatalError: {
          message: action.payload.errorMessage,
          retryConnection: action.payload.retryConnection,
        },
        bannerMsgs: state.bannerMsgs,
      };
    }
    case SAVE_DEVICE_AUTHN_METHODS: {
      const authnDeviceMethods = action.payload;
      return {
        ...state,
        isLoading: true,
        authnDeviceMethods,
      };
    }
    case SWITCH_AUTHN_METHOD: {
      const { authnMethod, userKnown } = action.payload;
      return {
        ...state,
        isLoading: false,
        currentAuthnMethod: authnMethod,
        userKnown,
      };
    }
    case SAVE_AUTHN_DATA: {
      const authnData = action.payload;
      return {
        ...state,
        authnData,
      };
    }
    case SAVE_FACTOR_OPTIONS: {
      const { tenantId, authnMethods } = action.payload;
      return {
        ...state,
        tenantId,
        authnMethods,
        isLoading: false,
        tenantIdChecked: true,
        currentAuthnMethod: authnMethods[0],
      };
    }
    case CREATE_SESSION_SUCCESS: {
      const sessionId = action.payload;
      return {
        ...state,
        sessionId,
        authenticationSessionCreated: true,
        isLoading: false,
        fatalError: undefined,
      };
    }
    case CREATE_SESSION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        tenantIdError: error,
      };
    }
    case CREATE_AUTH_ERROR: {
      return {
        ...state,
        authError: action.payload.errorMessage,
      };
    }
    case CLEAR_SESSION: {
      return {
        ...state,
        sessionId: '',
        authenticationSessionCreated: false,
        isLoading: false,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        isLoading: false,
        pushNotificationExpiredError: '',
        fatalError: undefined,
        tenantIdError: '',
        authError: undefined,
        bannerMsgs: [],
      };
    }
    case AUTHENTICATE_SUCCESS: {
      const { userId, fullUsername, authnToken } = action.payload;
      return {
        ...state,
        userId,
        fullUsername,
        authnToken,
        isLoading: false,
        success: true,
        fatalError: undefined,
      };
    }
    case getType(saveUsername): {
      return {
        ...state,
        fullUsername: action.payload,
        userKnown: true,
      };
    }
    case PUSH_NOTIFICATION_ACCEPTED_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        fatalError: undefined,
      };
    }
    case START_ENROLLMENT_CEREMONY: {
      return {
        ...state,
        sessionId: '',
        isEnrollmentCeremony: true,
        userKnown: false,
        authenticationSessionCreated: false,
      };
    }
    case PUSH_NOTIFICATION_EXPIRED_ERROR: {
      return {
        ...state,
        pushNotificationExpiredError: ErrorMessages.PUSH_NOTIFICATION_EXPIRED,
      };
    }
    case getType(setModelData): {
      const { modelData } = action.payload;
      return { ...state, modelData };
    }
    case getType(createAuthenticationSession.success): {
      return {
        ...state,
        sessionId: action.payload || '1',
        authenticationSessionCreated: true,
      };
    }
    case getType(createEnrollmentSession.success): {
      return {
        ...state,
        enrollmentSessionCreated: true,
      };
    }
    case getType(setBanners): {
      return {
        ...state,
        bannerMsgs: action.payload,
      };
    }
    case getType(clearErrorBanners): {
      return {
        ...state,
        bannerMsgs: state.bannerMsgs.filter(
          item => item.type === AuthBannerType.SUCCESS,
        ),
      };
    }
    case getType(authenticateAction.request): {
      return {
        ...state,
        authError: undefined,
        isEnrollmentCeremony: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
