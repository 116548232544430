// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Space, Button, Typography, Select } from 'antd';
import {
  AuthenticationReadinessStatusCode,
  AuthenticationResultStatusCode,
} from '../../../types';
import { usePersistentSessionState } from '../../../hooks';

export const AuthenticationStatusSection = () => {
  const [state, setState] = usePersistentSessionState({
    tu__authentication_status: AuthenticationResultStatusCode.SUCCESS,
  });

  return (
    <Space direction="vertical" style={{ padding: 10 }}>
      <Typography.Title level={4}>Authentication status</Typography.Title>
      <Space direction="horizontal">
        <Button
          onClick={() => {
            window.impr._config.sendAuthenticationStatus(
              state.tu__authentication_status,
            );
          }}
        >
          Send
        </Button>
        <Select
          style={{ width: '280px' }}
          defaultValue={state.tu__authentication_status}
          onChange={statusCode => {
            setState({ tu__authentication_status: statusCode });
          }}
        >
          <Select.Option value={AuthenticationReadinessStatusCode.READY}>
            {AuthenticationReadinessStatusCode.READY}
          </Select.Option>
          <Select.Option value={AuthenticationReadinessStatusCode.NOT_READY}>
            {AuthenticationReadinessStatusCode.NOT_READY}
          </Select.Option>
          <Select.Option value={AuthenticationResultStatusCode.SUCCESS}>
            {AuthenticationResultStatusCode.SUCCESS}
          </Select.Option>
          <Select.Option value={AuthenticationResultStatusCode.FAILURE}>
            {AuthenticationResultStatusCode.FAILURE}
          </Select.Option>
        </Select>
      </Space>
    </Space>
  );
};
