// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AxiosRequestConfig } from 'axios';
import { Tracer } from './Tracer';
import { getXRayDescriptor } from './utils';

export const CONTEXT_PROPAGATION_HEADER_NAME = 'X-Amzn-Trace-Id';

export const createContextPropagationInterceptor =
  (tracer: Tracer) =>
  (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
    if (requestConfig.headers) {
      const span = tracer.getCurrentSpan();
      if (span) {
        requestConfig.headers[CONTEXT_PROPAGATION_HEADER_NAME] =
          getXRayDescriptor(span);
      }
    }

    return requestConfig;
  };
