// Copyright 2022, Imprivata, Inc.  All rights reserved.

import opentelemetry, {
  Span,
  TraceFlags,
  Tracer as OTTracer,
} from '@opentelemetry/api';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider } from '@opentelemetry/web';
import { ZipkinExporter } from '@opentelemetry/exporter-zipkin';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import {
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  BatchSpanProcessor,
} from '@opentelemetry/tracing';
import { SpansStore, TracerOptions } from './types';
import { CITsSpansCollector } from './CITsSpansCollector';
import { getB3Descriptor, stringifyObjectProperties } from './utils';
import { TraceContext } from '../types';
import { AWSXRayIdGenerator } from '@opentelemetry/id-generator-aws-xray';
import { AWSXRayPropagator } from '@opentelemetry/propagator-aws-xray';

// eslint-disable-next-line
export type RawSpanAttributes = Record<string, any>;

/*
 * Provides an interface to easily start/end tracing spans
 * Spans are created by name
 * When creating a child span parent span is identified also by name
 */
export class Tracer {
  private readonly journeyID: string | null;
  private readonly store: SpansStore = new Map();
  // is used do determine `currentSpan`, it can and should be done via opentelemetry
  // context API, but it appeared to be complicated so can't implement it now
  private readonly creationStack: Span[] = [];

  private readonly provider: WebTracerProvider;
  private readonly tracer: OTTracer;

  static currentTracerInstance?: Tracer;

  constructor(
    serviceName: string,
    journeyID: string | null,
    options: Partial<TracerOptions> = {},
  ) {
    this.journeyID = journeyID;
    const provider = new WebTracerProvider({
      resource: Resource.default().merge(
        new Resource({
          [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
        }),
      ),
      idGenerator: new AWSXRayIdGenerator(),
    });

    if (options.zipkinExportUrl) {
      provider.addSpanProcessor(
        new BatchSpanProcessor(
          new ZipkinExporter({ url: options.zipkinExportUrl }),
        ),
      );
    }

    if (options.otlpExportUrl) {
      provider.addSpanProcessor(
        new BatchSpanProcessor(
          new OTLPTraceExporter({ url: options.otlpExportUrl }),
        ),
      );
    }

    if (options.logToConsole) {
      provider.addSpanProcessor(
        new SimpleSpanProcessor(new ConsoleSpanExporter()),
      );
    }

    if (options.collectSpans) {
      provider.addSpanProcessor(
        new SimpleSpanProcessor(new CITsSpansCollector()),
      );
    }

    provider.register({
      propagator: new AWSXRayPropagator(),
    });

    this.provider = provider;
    this.tracer = provider.getTracer(serviceName);
    Tracer.currentTracerInstance = this;
  }

  startSpan(name: string, attributes?: RawSpanAttributes): void {
    if (this.doesSpanExistInStore(name)) {
      this.logSpanAlreadyExistsError(name);

      return;
    }

    const journeyIdAttributeName = 'impr.astra.journey_id';
    const attributesWithJourneyId = {
      [journeyIdAttributeName]: this.journeyID,
      ...(attributes || {}),
    };

    const span = this.tracer.startSpan(name, {
      attributes: stringifyObjectProperties(attributesWithJourneyId),
    });
    this.storeSpan(name, span);
    this.addSpanToStack(span);
  }

  startSpanFromContext(name: string, traceContext: TraceContext): void {
    const parentSpanContext = traceContext.spanContext;
    const [traceId, spanId] = parentSpanContext.split('-');
    const span = this.tracer.startSpan(
      name,
      {},
      opentelemetry.trace.setSpan(
        opentelemetry.context.active(),
        opentelemetry.trace.wrapSpanContext({
          traceId,
          spanId,
          isRemote: true,
          traceFlags: TraceFlags.SAMPLED,
        }),
      ),
    );

    this.storeSpan(name, span);
    this.addSpanToStack(span);
  }

  startSubspan(name: string, attributes?: RawSpanAttributes): void {
    const currentSpan = this.getCurrentSpan();
    if (currentSpan) {
      // eslint-disable-next-line
      // @ts-ignore
      this.startSubspanFromParent(name, currentSpan.name, attributes);
    } else {
      this.startSpan(name, attributes);
    }
  }

  startSubspanFromParent(
    name: string,
    parentName: string,
    attributes?: RawSpanAttributes,
  ): void {
    if (this.doesSpanExistInStore(name)) {
      this.logSpanAlreadyExistsError(name);

      return;
    }

    const parentSpan = this.getSpanFromStore(parentName);

    if (parentSpan) {
      const parentContext = opentelemetry.trace.setSpan(
        opentelemetry.context.active(),
        parentSpan,
      );

      const strirgifiedAttributes = stringifyObjectProperties(attributes || {});
      const span = this.tracer.startSpan(
        name,
        { attributes: strirgifiedAttributes },
        parentContext,
      );

      this.storeSpan(name, span);
      this.addSpanToStack(span);
    } else {
      this.logSpanNotFoundError(parentName);
    }
  }

  endSpan(name: string, attributes?: RawSpanAttributes): void {
    const span = this.getSpanFromStore(name);

    if (span) {
      if (attributes) {
        span.setAttributes(stringifyObjectProperties(attributes));
      }

      this.removeSpanFromStore(name);
      this.removeSpanFromStack(span);
      span.end();
    } else {
      this.logSpanNotFoundError(name);
    }
  }

  addSpanAttributes(name: string, attributes: RawSpanAttributes): void {
    const span = this.getSpanFromStore(name);

    if (span) {
      span.setAttributes(stringifyObjectProperties(attributes));
    } else {
      this.logSpanNotFoundError(name);
    }
  }

  getTraceContext(): TraceContext {
    const currentSpan = this.getCurrentSpan();

    return {
      journeyID: this.journeyID,
      spanContext: currentSpan ? getB3Descriptor(currentSpan) : '',
    };
  }

  endAllSpans() {
    Array.from(this.store.keys()).forEach(spanName => {
      this.endSpan(spanName);
    });
  }

  forceFlush() {
    this.provider.forceFlush();
  }

  doesSpanExist(name: string): boolean {
    return this.doesSpanExistInStore(name);
  }

  getCurrentSpan(): Span {
    return this.getStackTopSpan();
  }

  getJourneyId(): string {
    return this.journeyID || '';
  }

  private storeSpan(name: string, span: Span) {
    this.store.set(name, span);
  }

  private getSpanFromStore(name: string) {
    return this.store.get(name);
  }

  private doesSpanExistInStore(name: string) {
    return this.store.has(name);
  }

  private removeSpanFromStore(name: string) {
    this.store.delete(name);
  }

  private addSpanToStack(span: Span) {
    this.creationStack.push(span);
  }

  private removeSpanFromStack(span: Span) {
    const spanIndex = this.creationStack.findIndex(
      stackSpan => stackSpan === span,
    );
    this.creationStack.splice(spanIndex, 1);
  }

  private getStackTopSpan() {
    return this.creationStack[this.creationStack.length - 1];
  }

  private logSpanNotFoundError(name: string) {
    console.error(`span "${name}" not found`);
  }

  private logSpanAlreadyExistsError(name: string) {
    console.error(`span "${name}" already exists`);
  }

  _testing = {
    getSpansStore: (): SpansStore => {
      return this.store;
    },
  };
}
