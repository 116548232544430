// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { EventsType } from 'react-idle-timer';

export enum ErrorMessages {
  'INCORRECT_USER_ID' = 'INCORRECT_USER_ID',
  'INCORRECT_USER_NAME' = 'INCORRECT_USER_NAME',
  'INSUFFICIENT_FACTOR_OPTIONS' = 'INSUFFICIENT_FACTOR_OPTIONS',
  'INCORRECT_PASSWORD' = 'INCORRECT_PASSWORD',
  'SESSION_ERROR' = 'SESSION_ERROR',
  'INCORRECT_TENANT_ID' = 'INCORRECT_TENANT_ID',
  'MISSING_QUERY_PARAMS' = 'MISSING_QUERY_PARAMS',
  'SESSION_EXPIRED' = 'SESSION_EXPIRED',
  'FATAL_ERROR' = 'FATAL_ERROR',
  'NOT_ENROLLED_CARD' = 'NOT_ENROLLED_CARD',
  'PASSWORD_EXPIRED' = 'PASSWORD_EXPIRED',
  'ACCOUNT_DISABLED' = 'ACCOUNT_DISABLED',
  'UXID_MULTIPLEUSERS' = 'UXID_MULTIPLEUSERS',
  'PUSH_NOTIFICATION_EXPIRED' = 'PUSH_NOTIFICATION_EXPIRED',
  'UXID_AUTH_BADTOKENPASSCODE' = 'UXID_AUTH_BADTOKENPASSCODE',
}

export enum ErrorCodes {
  SESSION_EXPIRED = 'session-expired',
  INVALID_SESSION_ID = 'invalid-session-id',
  CREDENTIAL_EXPIRED = 'credential-expired',
  ACCOUNT_DISABLED = 'idp-disabled',
  AUTHENTICATOR_NOT_ENROLLED = 'authenticator-not-enrolled',
  FACTORS_INSUFFICIENT = 'factors-insufficient',
  AMBIGUOUS_USERNAME = 'ambiguous-username',
  INVALID_CREDENTIALS = 'invalid-credentials',
}

export const INVALID_SESSION_LOGOUT_CODES: string[] = [
  ErrorCodes.SESSION_EXPIRED,
  ErrorCodes.INVALID_SESSION_ID,
];

export const QueryParams = {
  TENANT_ID: 'tenantId',
  CONTEXT_TYPE: 'contextType',
  RESOURCE_TYPE: 'resourceType',
  AUTHN_API_VERSION: 'authnApiVersion', // for testing
};
export const BANNER_DISPLAY_TIMEOUT_SEC = 15;
export const INLINE_ENROLLMENT_DISPLAY_TIMEOUT_SEC = 30;
export const USER_ACTIVITIES_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
];
