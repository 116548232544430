// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { ProxReader } from '../devices';
import {
  AuthnMethod,
  AgentEvent,
  AppAuthenticationCanceledEventHandler,
  AppAuthenticationStatusEventHandler,
  FactorDataReadyEventHandler,
  CloseWindowEventHandler,
  ErrorEventHandler,
  FactorChangedEventHandler,
} from '../types';

export class MemorySection {
  registeredCallbacks: {
    [AgentEvent.FACTOR_DATA_READY]?: FactorDataReadyEventHandler;
    [AgentEvent.FACTOR_CHANGED]?: FactorChangedEventHandler;
    [AgentEvent.ERROR]?: ErrorEventHandler;
    [AgentEvent.CLOSE_WINDOW]?: CloseWindowEventHandler;
    [AgentEvent.APP_AUTHENTICATION_STATUS]?: AppAuthenticationStatusEventHandler;
    [AgentEvent.APP_AUTHENTICATION_CANCELED]?: AppAuthenticationCanceledEventHandler;
  } = {};

  connectedDevices = new Map<AuthnMethod, ProxReader>();
  submittedCredentials: ImprCredential[] = [];

  appAccessContext = '';
  // Can be used for local testing:
  // appAccessContext = jsonToBase64({ username: 'imprid' });
  isWindowShown = false;
  onShowWindowChangeCallback?: (isWindowShown: boolean) => void;
}
