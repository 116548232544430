// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction } from 'typesafe-actions';
import { AppAccessContext } from '../authn-module/types';

export const errorAction = createAction('error')();

export const subscribeToAuthResponseAction = createAction(
  'app_init/SUBSCRIBE_TO_AUTH_RESPONSE',
)();

export const readAppAccessContext = createAction('read-app-access-context')();
export const saveAppAccessContext = createAction(
  'save-app-access-context',
)<AppAccessContext>();
