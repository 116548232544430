// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, Epic } from 'redux-observable';
import { filter, switchMap, of, map, EMPTY } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { EpicDependencies, Action, RootState } from '../types';
import { errorAction, subscribeToAuthResponseAction } from '../actions';
import { tracer } from '../../authn-module/tracing';
import {
  AuthenticationReadinessStatusCode,
  AuthenticationResultStatusCode,
} from '../../endpoint-agent-mock/types';

export const subscribeToAuthResponseEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<
    EpicDependencies,
    'authenticationStatus$' | 'endJourneyAndCloseWindow' | 'showWindow'
  >
> = (action$, _, dependencies) => {
  const { authenticationStatus$, endJourneyAndCloseWindow, showWindow } =
    dependencies;

  return action$.pipe(
    filter(isActionOf(subscribeToAuthResponseAction)),
    switchMap(() => authenticationStatus$),
    switchMap(args =>
      of(args).pipe(
        map(([traceContext, statusData]) => {
          tracer.startSpanFromContext(
            'authentication_status_event',
            traceContext,
          );
          tracer.addSpanAttributes('authentication_status_event', {
            statusData,
          });

          return statusData.statusCode;
        }),
        switchMap(statusCode => {
          switch (statusCode) {
            case AuthenticationResultStatusCode.FAILURE: {
              return of(
                errorAction(),
                // startReset({
                //   banners: [
                // {
                //   type: AuthBannerType.ERROR,
                //   primary: i18n.t('errors.SOMETHING_UNEXPECTED'),
                //   size: AuthBannerSize.TALL,
                // },
                //   ],
                // }),
              );
            }
            case AuthenticationResultStatusCode.SUCCESS: {
              endJourneyAndCloseWindow(
                tracer.getJourneyId(),
                tracer.getTraceContext(),
              );

              tracer.endAllSpans();
              tracer.forceFlush();
              break;
            }

            case AuthenticationReadinessStatusCode.READY:
              showWindow(true);
              break;
            case AuthenticationReadinessStatusCode.NOT_READY:
              showWindow(false);
              break;
            default:
              console.log('Unknown status code:', statusCode);
          }
          tracer.endSpan('authentication_status_event');

          return EMPTY;
        }),
      ),
    ),
  );
};

export const agentEpic = combineEpics(subscribeToAuthResponseEpic);
