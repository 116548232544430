// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod, FactorCategory } from '../types';

export enum Headers {
  ImprTenantId = 'Impr-Tenant-Id',
  ImprSessionId = 'Impr-Session-Id',
  ImprCodingCtx = 'Impr-Coding-Ctx',
  XContentTypeOptions = 'X-Content-Type-Options',
  XSSProtection = 'X-XSS-Protection',
  XFrameOptions = 'X-Frame-Options',
  StrictTransportSecurity = 'Strict-Transport-Security',
}

// API version for authn and enroll
export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export const AuthnFactor = {
  PROX: { factorType: AuthnMethod.PROX, factorCategory: FactorCategory.KNOW },
  PWD: {
    factorType: AuthnMethod.USERNAME_PASSWORD,
    factorCategory: FactorCategory.KNOW,
  },
};
