// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthBannerProps } from '@imprivata-cloud/astra-ui-component-library';
import { createAction, createAsyncAction, Action } from 'typesafe-actions';
import {
  FactorOption,
  AuthnMethod,
  AuthnData,
  TraceContext,
  ModelData,
} from '../../../types';
import { ErrorMessages } from '../constants';

import {
  START_LOADING,
  STOP_LOADING,
  FATAL_ERROR,
  SAVE_DEVICE_AUTHN_METHODS,
  SAVE_AUTHN_DATA,
  SWITCH_AUTHN_METHOD,
  SAVE_FACTOR_OPTIONS,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_ERROR,
  CREATE_AUTH_ERROR,
  AUTHENTICATE_SUCCESS,
  CLEAR_SESSION,
  CLEAR_ERRORS,
  START_ENROLLMENT_CEREMONY,
  START_RESET,
  STOP_RESET,
  PUSH_NOTIFICATION_EXPIRED_ERROR,
  PUSH_NOTIFICATION_ACCEPTED,
  PUSH_NOTIFICATION_ACCEPTED_SUCCESS,
} from './constants';

export const startAppInit = createAction('app_init/START')();

export const startLoading = createAction(START_LOADING)();

export const stopLoading = createAction(STOP_LOADING)();

export const startReset = createAction(START_RESET)<{
  banners: AuthBannerProps[];
} | void>();

export const stopReset = createAction(STOP_RESET)();

export const fetchFactorOptions = createAsyncAction(
  'fetch-factor-options/REQUEST',
  'fetch-factor-options/SUCCESS',
  'fetch-factor-options/FAIL',
)<
  { agentFactors: AuthnMethod[]; traceContext: TraceContext } | void,
  AuthnMethod[],
  void
>();

export const fatalError = createAction(FATAL_ERROR)<{
  errorMessage: ErrorMessages;
  retryConnection: boolean;
}>();

export const saveAuthnData = createAction(SAVE_AUTHN_DATA)<AuthnData>();

export const saveDeviceMethods = createAction(SAVE_DEVICE_AUTHN_METHODS)<
  Array<AuthnMethod>
>();

export const switchAuthnMethod = createAction(SWITCH_AUTHN_METHOD)<{
  authnMethod: FactorOption;
  userKnown: boolean;
}>();

export const saveFactorOptions = createAction(SAVE_FACTOR_OPTIONS)<{
  tenantId: string;
  authnMethods: Array<FactorOption>;
}>();

export const setModelData = createAction('SET_MODEL_DATA')<{
  modelData: ModelData[] | undefined;
}>();

export const createSessionSuccess = createAction(
  CREATE_SESSION_SUCCESS,
)<string>();

export const saveUsername = createAction('SAVE_USERNAME')<string>();

export const createSessionError = createAction(CREATE_SESSION_ERROR)<string>();

export const createAuthError = createAction(CREATE_AUTH_ERROR)<{
  errorMessage: string; // TODO: get rid of nesting, put it directly as payload
}>();

export const clearSession = createAction(CLEAR_SESSION)();

export const clearErrors = createAction(CLEAR_ERRORS)();

export type FactorData = {
  version: string;
  username: string;
  password: string;
};
export const authenticateAction = createAsyncAction(
  'authenticate/REQUEST',
  'authenticate/SUCCESS',
  'authenticate/FAIL',
)<{ authnData: AuthnData; traceContext?: TraceContext }, void, void>();

export const enrollCardAction = createAsyncAction(
  'inline-enrollment/REQUEST',
  'inline-enrollment/SUCCESS',
  'inline-enrollment/FAIL',
)<
  {
    username: string;
    password: string;
  },
  void,
  void
>();

export const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS)<{
  userId: string;
  fullUsername: string;
  authnToken: string;
}>();

export const pushAccepted = createAction(PUSH_NOTIFICATION_ACCEPTED)<{
  samlToken: string;
}>();

export const startEnrollmentCeremony = createAction(
  START_ENROLLMENT_CEREMONY,
)();

export const setBanners = createAction('banner/SET')<AuthBannerProps[]>();
export const clearErrorBanners = createAction('banner/CLEAR_ERRORS')();

export const pushNotificationExpiredError = createAction(
  PUSH_NOTIFICATION_EXPIRED_ERROR,
)();

export const pushNotificationAcceptedSuccess = createAction(
  PUSH_NOTIFICATION_ACCEPTED_SUCCESS,
)();

export const createAuthenticationSession = createAsyncAction(
  'create-authentication-session/REQUEST',
  'create-authentication-session/SUCCESS',
  'create-authentication-session/FAIL',
)<
  {
    samlData?: string;
    dispatchAfter?: Action;
    traceContext?: TraceContext;
  } | void,
  string,
  void
>();

export const createEnrollmentSession = createAsyncAction(
  'create-enrollment-session/REQUEST',
  'create-enrollment-session/SUCCESS',
  'create-enrollment-session/FAIL',
)<
  {
    dispatchAfter: Action;
  } | void,
  void,
  void
>();

export const subscribeToFactorChangedAction = createAction(
  'subscribe-to-factor-changed',
)();

export const subscribeToAuthnDataReadyAction = createAction(
  'subscribe-to-authn-data-ready',
)();
