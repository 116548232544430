// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios from 'axios';
import getConfigs from '../../appConfigUtils';
import { ApiVersion, Headers } from './constants';

import {
  AuthenticateDataV1V2,
  AuthenticatePayload,
  AuthnContext,
  AuthnData,
  AuthnMethod,
  CreateSessionPayload,
  CredentialsDataDecrypted,
  EnrollPayload,
  GetFactorOptionsPayload,
  NextFactorsGetResponseV1V2,
  OrgPreferencesPayload,
  PushResponsePayload,
} from '../types';
import { CodingContext, Secret } from '@imprivata-cloud/data-privacy-js';
import { base64ToJson } from '../utils/base64';

let codingContext: CodingContext;

const { API_URL } = getConfigs();

// I do this instead of global API_VERSION like API_URL so that I can unit test
function isAuthnApiV2(): boolean {
  return authnApiVersion() === ApiVersion.V2;
}
export function authnApiVersion(): ApiVersion {
  return getConfigs().AUTHN_API_VERSION;
}

export function setupCommonHeaders(): void {
  axios.defaults.headers.common[Headers.StrictTransportSecurity] =
    'max-age=86400;includeSubDomains';
  axios.defaults.headers.common[Headers.XSSProtection] = '1;mode=block';
  axios.defaults.headers.common[Headers.XFrameOptions] = 'DENY';
  axios.defaults.headers.common[Headers.XContentTypeOptions] = 'nosniff';
  axios.defaults.withCredentials = true;
}

export async function getFactorOptions(
  tenantId: string,
  availableFactorTypes: Array<AuthnMethod>,
  contextType?: string,
  resourceType?: string,
  username?: string,
): Promise<GetFactorOptionsPayload> {
  axios.defaults.headers.common[Headers.ImprTenantId] = tenantId;
  setupCommonHeaders();

  const nextFactorsPath = isAuthnApiV2() ? 'next-factors' : 'factor-options';
  const url = `${API_URL}/authn-web/authn/${authnApiVersion()}/${nextFactorsPath}/get`;
  const body =
    isAuthnApiV2() && username
      ? {
          authnContext: { resourceType, contextType },
          availableFactorTypes,
          username,
        }
      : {
          authnContext: { resourceType, contextType },
          availableFactorTypes,
        };
  const axiosResult: NextFactorsGetResponseV1V2 = await axios.post(url, body);
  if (isAuthnApiV2()) {
    axiosResult.data.factorOptions = axiosResult.data.nextFactors;
    delete axiosResult.data.nextFactors;
  }
  return axiosResult as GetFactorOptionsPayload;
}

export async function createSession(
  availableFactorTypes: Array<AuthnMethod>,
  authnContext: AuthnContext,
  enrollment = false,
  username?: string,
): Promise<CreateSessionPayload> {
  // Sending old session when creating new session messes up backend - happens after inline enrollment
  delete axios.defaults.headers.common[Headers.ImprSessionId];
  let axiosConfig = {};
  if (isAuthnApiV2()) {
    codingContext = CodingContext.createContext();
    const contextHeader = codingContext.buildImprCodingCtxHeader();
    axiosConfig = {
      headers: {
        [Headers.ImprCodingCtx]: contextHeader,
      },
    };
  }
  const body =
    isAuthnApiV2() && username
      ? {
          authnContext,
          availableFactorTypes,
          username,
        }
      : {
          authnContext,
          availableFactorTypes,
        };
  const completeUrl = enrollment
    ? `${API_URL}/authn-web/enroll/${authnApiVersion()}/enroll-session/start`
    : `${API_URL}/authn-web/authn/${authnApiVersion()}/authn-session/start`;
  return axios.post(`${completeUrl}`, body, axiosConfig);
}

export async function enroll(
  authnData: AuthnData,
  sessionId: string,
): Promise<EnrollPayload> {
  if (authnApiVersion() === ApiVersion.V1) {
    axios.defaults.headers.common[Headers.ImprSessionId] = sessionId;
  }
  setupCommonHeaders();
  const url = `${API_URL}/authn-web/enroll/${authnApiVersion()}/enroll`;
  if (isAuthnApiV2()) {
    const factorDataObj = base64ToJson(authnData.factorData);
    const systemDataObj = base64ToJson(authnData.systemData);
    return axios.post(url, {
      ...authnData,
      factorData: codingContext.encryptJson(factorDataObj),
      systemData: codingContext.encryptJson(systemDataObj),
    });
  }
  return axios.post(url, authnData);
}

export async function authenticate(
  authnData: AuthnData,
  options?: {
    isEnrollee: boolean;
  },
): Promise<AuthenticatePayload> {
  const url = options?.isEnrollee
    ? `${API_URL}/authn-web/enroll/v2/enrollee/authenticate`
    : `${API_URL}/authn-web/authn/v2/authenticate`;

  const factorDataObj = base64ToJson(authnData.factorData);
  const systemDataObj = base64ToJson(authnData.systemData);
  const axiosResultV2: AuthenticateDataV1V2 = await axios.post(url, {
    ...authnData,
    factorData: codingContext.encryptJson(factorDataObj),
    systemData: codingContext.encryptJson(systemDataObj),
  });
  return authenticateDataFromV2toV1(axiosResultV2);
}

export async function getOrgPreferences(
  tenantId: string,
): Promise<OrgPreferencesPayload> {
  axios.defaults.headers.common[Headers.ImprTenantId] = tenantId;

  return axios
    .post(`${API_URL}/authn-web/org-preferences/v1/org-preferences/get`)
    .then(res => res.data);
}

export async function getPushStatus(
  tenantId: string,
): Promise<PushResponsePayload> {
  axios.defaults.headers.common[Headers.ImprTenantId] = tenantId;

  const response = await axios
    .post(`${API_URL}/authn-web/authn/v2/push-authn-status/get`, {
      factorType: 'impr-id',
    })
    .then(res => res.data);

  if (response.credentialsData) {
    const credentialsDataDecrypted = decrypt(response.credentialsData);
    response.samlToken = credentialsDataDecrypted.saml;
  }

  return response;
}

export function setDefaultHeader(headerName: string, value: string): void {
  axios.defaults.headers.common[headerName] = value;
}

export function deleteHeader(headerName: string): void {
  delete axios.defaults.headers.common[headerName];
}

function decrypt(credentialsData: Secret): CredentialsDataDecrypted {
  return codingContext.decryptToJson(
    credentialsData,
  ) as CredentialsDataDecrypted;
}

export function encrypt(objectToEncrypt: Record<string, unknown>): Secret {
  return codingContext.encryptJson(objectToEncrypt);
}

function authenticateDataFromV2toV1(
  authenticateDataV1V2: AuthenticateDataV1V2,
): AuthenticatePayload {
  authenticateDataV1V2.data.factorOptions =
    authenticateDataV1V2.data.nextFactors;
  delete authenticateDataV1V2.data.nextFactors;

  const credentialsData = authenticateDataV1V2.data.credentialsData;
  if (credentialsData?.data) {
    const credentialsDataDecrypted = decrypt(credentialsData);
    authenticateDataV1V2.data.authnToken = credentialsDataDecrypted.saml;
  } else {
    authenticateDataV1V2.data.authnToken = '';
  }
  delete authenticateDataV1V2.data.credentialsData;

  return authenticateDataV1V2 as AuthenticatePayload;
}
