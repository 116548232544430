// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvide } from 'react-redux';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import { attachAgentMockIfNeeded } from './endpoint-agent-mock';
import { configureStore } from './store/configureStore';
import {
  readAppAccessContext,
  subscribeToAuthResponseAction,
} from './store/actions';
import { App } from './App';
import { ContextType, QueryParamName } from './authn-module/types';
import { getQueryParams } from './authn-module/utils';

attachAgentMockIfNeeded();

const store = configureStore();

store.dispatch(subscribeToAuthResponseAction());

const queryParams = getQueryParams();
if (
  queryParams.get(QueryParamName.CONTEXT_TYPE) ===
  ContextType.EPCS_ORDER_SIGNING
) {
  store.dispatch(readAppAccessContext());
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvide store={store}>
      <App />
    </ReduxProvide>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
