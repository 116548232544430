// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const START_LOADING = 'START_LOADING' as const;
export const STOP_LOADING = 'STOP_LOADING' as const;

export const START_RESET = 'START_RESET' as const;
export const STOP_RESET = 'STOP_RESET' as const;

export const FATAL_ERROR = 'FATAL_ERROR' as const;
export const CLEAR_ERRORS = 'CLEAR_ERRORS' as const;

export const SAVE_DEVICE_AUTHN_METHODS = 'SAVE_DEVICE_AUTHN_METHODS' as const;
export const SAVE_AUTHN_DATA = 'SAVE_AUTHN_DATA' as const;
export const SWITCH_AUTHN_METHOD = 'SWITCH_AUTHN_METHOD' as const;

export const SAVE_FACTOR_OPTIONS = 'SAVE_FACTOR_OPTIONS' as const;

export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS' as const;
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR' as const;
export const CREATE_AUTH_ERROR = 'CREATE_AUTH_ERROR' as const;
export const CLEAR_SESSION = 'CLEAR_SESSION' as const;

export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS' as const;

export const PUSH_NOTIFICATION_ACCEPTED_SUCCESS =
  'PUSH_NOTIFICATION_ACCEPTED_SUCCESS' as const;

export const START_ENROLLMENT_CEREMONY = 'START_ENROLLMENT_CEREMONY' as const;

export const PUSH_NOTIFICATION_EXPIRED_ERROR =
  'PUSH_NOTIFICATION_EXPIRED_ERROR' as const;
export const PUSH_NOTIFICATION_ACCEPTED = 'PUSH_NOTIFICATION_ACCEPTED' as const;

export const SET_MODEL_DATA = 'SET_MODEL_DATA' as const;

export type ActionType =
  | typeof START_LOADING
  | typeof STOP_LOADING
  | typeof FATAL_ERROR
  | typeof START_RESET
  | typeof STOP_RESET
  | typeof SAVE_DEVICE_AUTHN_METHODS
  | typeof SWITCH_AUTHN_METHOD
  | typeof CREATE_SESSION_SUCCESS
  | typeof CREATE_SESSION_ERROR
  | typeof CREATE_AUTH_ERROR
  | typeof AUTHENTICATE_SUCCESS
  | typeof SAVE_FACTOR_OPTIONS
  | typeof CLEAR_SESSION
  | typeof CLEAR_ERRORS
  | typeof START_ENROLLMENT_CEREMONY
  | typeof SAVE_AUTHN_DATA
  | typeof PUSH_NOTIFICATION_ACCEPTED
  | typeof PUSH_NOTIFICATION_ACCEPTED_SUCCESS;
