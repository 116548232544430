// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { agentEpic } from './agentEpic';
import { readAppAccessContextEpic } from './readAppAccessContextEpic';
import { Action, EpicDependencies, RootState } from '../types';

export const rootEpic = combineEpics<
  Action,
  Action,
  RootState,
  EpicDependencies
>(agentEpic, readAppAccessContextEpic);
