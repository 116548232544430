import { Epic } from 'redux-observable';
import {
  catchError,
  EMPTY,
  filter,
  from,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  createEnrollmentSession,
  enrollCardAction,
  startLoading,
  startReset,
  stopLoading,
} from '../actions';
import { Action } from '../rootAction';
import { ILoginState } from '../types';
import { SpanNames, tracer } from '../../../../tracing';
import { createAuthSuccessBannerMsg } from '@imprivata-cloud/astra-ui-component-library';
import i18n from '../../../../i18n';
import { mapErrorToAction } from '../../components/hooks';
import { EpicDependencies } from '../../../../store/types';
import { prepareAuthnData } from '../../utils';
import { AuthnMethod } from '../../../../types';

export const enrollmentEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'authenticate' | 'enroll'>
> = (action$, state$, dependencies) => {
  const { authenticate, enroll } = dependencies;

  return action$.pipe(
    filter(isActionOf(enrollCardAction.request)),
    switchMap(({ payload }) => {
      const { authnData, enrollmentSessionCreated } = state$.value;
      const { username, password } = payload;

      if (!enrollmentSessionCreated) {
        return of(
          createEnrollmentSession.request({
            dispatchAfter: enrollCardAction.request(payload),
          }),
        );
      }

      tracer.startSpan(SpanNames.AUTHENTICATE_REQUEST);
      return from(
        authenticate(
          prepareAuthnData(AuthnMethod.USERNAME_PASSWORD, {
            version: '1.0',
            username,
            password,
          }),
          { isEnrollee: true },
        ),
      ).pipe(
        switchMap(response => {
          tracer.endSpan(SpanNames.AUTHENTICATE_REQUEST, { response });

          if (authnData) {
            tracer.startSpan(SpanNames.ENROLLMENT_REQUEST);
            return from(enroll(authnData, '')).pipe(
              switchMap(enrollResp => {
                tracer.endSpan(SpanNames.ENROLLMENT_REQUEST, {
                  response: enrollResp,
                });

                if (enrollResp) {
                  return of(
                    startReset({
                      banners: [
                        createAuthSuccessBannerMsg(
                          i18n.t('enroll.awesome-msg'),
                        ),
                      ],
                    }),
                  );
                }

                return EMPTY;
              }),
            );
          }

          return EMPTY;
        }),
        catchError((error: { code: string; message: string }) => {
          tracer.endAllSpans();

          if (error) {
            const action = mapErrorToAction(error);

            return of(action, stopLoading());
          }

          return of(stopLoading());
        }),
        startWith(startLoading()),
      );
    }),
  );
};
