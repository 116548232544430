// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { trimBaseUrl } from '../utils/path';
import getConfig from '../../appConfigUtils';

const { LOGGING_FOR_AGENT_ENABLED } = getConfig();
const isLoggingEnabled = LOGGING_FOR_AGENT_ENABLED !== 'false';
const isUnitTestEnv = process.env.NODE_ENV === 'test';

export function log(...messageParts: Array<string | number | boolean>) {
  if (isLoggingEnabled && !isUnitTestEnv) {
    console.log('[Authn UI]:', ...messageParts);
  }
}

export function logHttpRequest(config: AxiosRequestConfig): void {
  log(`HTTP to ${config.method}:${trimBaseUrl(config.url)}`);
}

export function logHttpSuccessResponse(response: AxiosResponse): void {
  const { status, statusText, config, data } = response;
  const { url, method } = config;
  const isError = !!data?.error;

  if (isError) {
    const errorText = JSON.stringify(data.error);
    const pathname = trimBaseUrl(url);
    log(
      `(!) HTTP error (${status} ${statusText}) (${errorText}) from ${method}:${pathname}`,
    );
  } else {
    log(`HTTP (${status} ${statusText}) from ${method}:${trimBaseUrl(url)}`);
  }
}

export function logHttpErrorResponse(error: AxiosError): void {
  const { status, statusText } = error.response || {};
  const { url, method } = error.config;
  log(`(!) HTTP (${status} ${statusText}) from ${method}:${trimBaseUrl(url)}`);
}
