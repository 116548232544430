// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod, OrgPreferencesPayload } from '../../../types';
import { TokenEntryForm } from '../../../components/login/token-entry-form';
import { PushNotification } from '../../../components/login/push-notification-form';
import {
  pushNotificationExpiredError,
  authenticateAction,
  pushAccepted,
} from '../store/actions';
import { prepareAuthnData } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginState } from '../store/types';
import { useState, useCallback } from 'react';

export const ImprIdContainer: React.FC<{
  orgPreferences?: OrgPreferencesPayload;
}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const [pushTimedOut, setPushTimedOut] = useState(false);
  const modelData = useSelector(
    (rootState: ILoginState) => rootState.modelData,
  );

  const handleTotpEntered = (totp: string) => {
    dispatch(
      authenticateAction.request({
        authnData: prepareAuthnData(AuthnMethod.IMPR_ID, {
          version: '1.0',
          totp,
        }),
      }),
    );
  };

  const onPushTimeOut = useCallback(() => {
    dispatch(pushNotificationExpiredError());
    setPushTimedOut(true);
  }, [dispatch]);

  const handlePushAccepted = useCallback(
    (samlToken: string): void => {
      dispatch(
        pushAccepted({
          samlToken,
        }),
      );
    },
    [dispatch],
  );

  const getComponentsToRender = () => {
    if (pushTimedOut) {
      return (
        <TokenEntryForm modelData={modelData} onSubmit={handleTotpEntered} />
      );
    } else {
      return (
        <PushNotification
          modelData={modelData}
          onPushAccepted={handlePushAccepted}
          onPushTimeOut={onPushTimeOut}
        />
      );
    }
  };

  return <div className="imprIdContainer">{getComponentsToRender()}</div>;
};
