// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Secret } from '@imprivata-cloud/data-privacy-js';

export interface FactorOption {
  factorType: AuthnMethod;
  factorCategory: string;
  pushable?: boolean;
}

export interface GetFactorOptionsPayload {
  data: {
    factorOptions: Array<FactorOption>;
  };
}

export interface NextFactorsGetResponseV1V2 {
  data: {
    factorOptions?: Array<FactorOption>; // v1
    nextFactors?: Array<FactorOption>; // v2
  };
}

export interface CreateSessionPayload {
  data: {
    sessionId: string;
  };
}

export interface ApiError {
  code: string;
  message: string;
}

export interface EnrollPayload {
  error: ApiError;
}

export type ModelData = {
  deviceModel: string;
  lastUsedAt: string; // ISO format
};

export interface AuthenticatePayload {
  fullUsername: string;
  userId: string;
  data: {
    factorOptions: Array<FactorOption>;
    authnToken: string;
    userKnown: boolean;
    modelData?: ModelData[];
  };
}

export interface AuthenticateDataV1V2 {
  fullUsername: string;
  userId: string;
  data: {
    factorOptions?: Array<FactorOption>; // v1
    nextFactors?: Array<FactorOption>; // v2
    authnToken?: string; // v1
    credentialsData?: Secret; // v2
    userKnown: boolean;
    pushInitiated: boolean;
    modelData?: ModelData[];
  };
}

export interface CredentialsDataDecrypted extends Record<string, unknown> {
  saml: string; // base64 encrypted saml xml
}

export interface OrgPreferencesPayload {
  logoUrl?: string;
  orgName?: string;
  userNameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface PushResponsePayload {
  status: PushStatus;
  credentialsData?: Secret; // v2
  samlToken?: string; // TODO revisit name
}

export enum AuthnMethod {
  PROX = 'prox',
  USERNAME_PASSWORD = 'pwd',
  IMPR_ID = 'impr-id',
}

export enum FactorCategory {
  KNOW = 'know',
  HAVE = 'have',
}

export enum PushStatus {
  ACCEPTED = 'accepted',
  NOT_AVAILABLE = 'not-available',
  PUSH_TIMEOUT = 'push-timeout',
}

export type AuthnData = {
  agentData: string;
  systemData: string;
  deviceData: string;
  factorType: string;
  factorData: string;
  samlData: string;
};

export type B3Descriptor = string;
export type TraceContext = {
  spanContext: B3Descriptor;
  journeyID: string | null;
};

export interface AuthnContext {
  contextType?: string;
  resourceType?: string;
  samlData: string;
}
