// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod } from '../types';

export enum AgentEvent {
  FACTOR_DATA_READY = 'factor-data-ready',
  FACTOR_CHANGED = 'factor-changed',
  ERROR = 'error',
  CLOSE_WINDOW = 'close-window',
  APP_AUTHENTICATION_STATUS = 'app-authentication-status',
  APP_AUTHENTICATION_CANCELED = 'app-authentication-canceled',
}

export type FactorFilter = { factorType: AuthnMethod[] } | null;
export type JourneyId = string;

export const QueryParamName = {
  CONTEXT_TYPE: 'contextType' as const,
  RESOURCE_TYPE: 'resourceType' as const,
};

export const ContextType = {
  EPCS_ORDER_SIGNING: 'epcs-order-signing' as const,
  CLINICAL_APP_LOGIN: 'clinical-app-login' as const,
};

export interface AppAccessContext extends Record<string, unknown> {
  username?: string;
  password?: string;
  domain?: string;
  usernameEditable?: boolean;
  samlData?: string;
}
