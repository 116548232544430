// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, InputBox } from '@imprivata-cloud/astra-ui-component-library';
import { useTranslation } from 'react-i18next';

interface IProps {
  initUsernameValue?: string;
  initPasswordValue?: string;
  disabled?: boolean;
  onSubmit: (username: string, password: string) => void;
  onCancel: () => void;
  userNameLabel?: string;
  passwordLabel?: string;
}

export const EnrollProxForm = ({
  initUsernameValue = '',
  initPasswordValue = '',
  disabled = false,
  onSubmit,
  onCancel,
  userNameLabel = '',
  passwordLabel = '',
}: IProps): JSX.Element => {
  const [username, setUsername] = useState(initUsernameValue);
  const [password, setPassword] = useState(initPasswordValue);
  const { t } = useTranslation();

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value.trim());
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value.trim());
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(username, password);
  };

  useEffect(() => {
    setPassword(initPasswordValue);
    setUsername(initUsernameValue);
  }, [initUsernameValue, initPasswordValue]);

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const inputs = e.currentTarget.form?.elements;
      const nextInputIndex = inputs
        ? Array.prototype.indexOf.call(inputs, e.currentTarget) + 1
        : -1;
      const nextInput = inputs ? inputs[nextInputIndex] : null;

      if (nextInput instanceof HTMLInputElement) {
        nextInput.focus();
      }
      if (nextInput instanceof HTMLButtonElement) {
        nextInput.click();
      }
    }
  };

  const finalizedLabel = userNameLabel
    ? t('login.username.custom-label', {
        username: userNameLabel,
        // Prevent HTML Entities from being screened twice - by i18n function and then by react
        interpolation: { escapeValue: false },
      })
    : t('login.username.label');

  const finalizedPasswordLabel = passwordLabel
    ? t('login.password.custom-label', {
        password: passwordLabel,
        // Prevent HTML Entities from being screened twice - by i18n function and then by react
        interpolation: { escapeValue: false },
      })
    : t('login.password.label');

  return (
    <div className="enrollCardWrapper">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="enrollCardHeader">
          <div data-testid="general-message" className="enrollTitle h3-header">
            {t('enroll.header-line-1')}
          </div>
          <br />
          <div
            data-testid="general-message-2"
            className="enrollSubtitle primary-body"
          >
            {t('enroll.header-line-2')}
          </div>
        </div>
        <label
          htmlFor="usernameInput"
          data-testid="username-input-label"
          className="enrollFormLabel secondary-body"
        >
          {finalizedLabel}
        </label>

        <InputBox
          type="text"
          autoComplete="off"
          placeholder={''}
          value={username}
          data-testid={'username-input'}
          onChange={handleUsernameChange}
          autoFocus={!disabled}
          disabled={disabled}
          onKeyDown={handleEnterKey}
          width="100%"
          size="large"
          id="usernameInput"
        />

        <label
          htmlFor="passwordInput"
          data-testid="password-input-label"
          className="enrollFormLabel secondary-body"
        >
          {finalizedPasswordLabel}
        </label>
        <InputBox
          type="password"
          placeholder={''}
          value={password}
          data-testid={'password-input'}
          onChange={handlePasswordChange}
          disabled={disabled}
          onKeyDown={handleEnterKey}
          width="100%"
          size="large"
          id="passwordInput"
        />
        <Button
          astraButtonType={['major']}
          label={t('actions.assign')}
          disabled={!username.length || !password.length || disabled}
          data-testid="submit-button"
          type="primary"
          block={true}
          htmlType="submit"
          id="submitButton"
        />
        <Button
          astraButtonType={['major']}
          label={t('actions.cancel')}
          disabled={disabled}
          data-testid="cancel-button"
          type="text"
          block={true}
          id="cancelButton"
          onClick={onCancel}
        />
      </form>
    </div>
  );
};
