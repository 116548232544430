// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { ExportResult, ExportResultCode } from '@opentelemetry/core';
import { SpanExporter, ReadableSpan } from '@opentelemetry/tracing';

/*
 * Custom exporter that stores all tracing spans on the window object
 * so that tracing can be tested with CITs
 *
 * TODO: is expected to be of no use when mockserver is migrated to Express
 */
export class CITsSpansCollector implements SpanExporter {
  constructor() {
    // eslint-disable-next-line
    // @ts-ignore
    window._spans = [];
  }

  export(spans: ReadableSpan[], cb: (result: ExportResult) => void): void {
    // eslint-disable-next-line
    // @ts-ignore
    window._spans.push(...spans);
    cb({ code: ExportResultCode.SUCCESS });
  }

  shutdown(): Promise<void> {
    return Promise.resolve();
  }
}
