import { Epic } from 'redux-observable';
import { of, from, filter, switchMap, catchError } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { ApiError } from '../../../../types';
import { mapErrorToAction } from '../../components/hooks';
import {
  authenticateAction,
  authenticateSuccess,
  clearErrors,
  createAuthenticationSession,
  saveAuthnData,
  setModelData,
  startEnrollmentCeremony,
  stopLoading,
  switchAuthnMethod,
} from '../actions';
import { Action } from '../rootAction';
import { SpanNames, tracer } from '../../../../tracing';
import { ILoginState } from '../types';
import { EpicDependencies } from '../../../../store/types';
import { ErrorCodes } from '../../constants';

export const authenticateEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'authenticate' | 'onSuccess'>
> = (action$, state$, dependencies) => {
  const { authenticate, onSuccess } = dependencies;

  return action$.pipe(
    filter(isActionOf(authenticateAction.request)),
    switchMap(({ payload }) => {
      const { authenticationSessionCreated } = state$.value;
      const { authnData, traceContext } = payload;

      if (!authenticationSessionCreated) {
        return of(
          createAuthenticationSession.request({
            samlData: authnData.samlData,
            dispatchAfter: authenticateAction.request(payload),
            traceContext,
          }),
        );
      }

      tracer.startSpan(SpanNames.AUTHENTICATE_REQUEST);

      return from(authenticate(authnData)).pipe(
        switchMap(response => {
          tracer.endSpan(SpanNames.AUTHENTICATE_REQUEST, { response });

          const { data, fullUsername, userId } = response;
          const { authnToken, factorOptions, modelData } = data;

          if (factorOptions.length > 0) {
            return of(
              clearErrors(),
              switchAuthnMethod({
                authnMethod: factorOptions[0],
                userKnown: true, // Temp
              }),
              setModelData({ modelData }),
            );
          } else {
            onSuccess({
              saml: authnToken,
            });

            return of(
              clearErrors(),
              authenticateSuccess({ userId, fullUsername, authnToken }),
            );
          }
        }),
        catchError((error: ApiError) => {
          tracer.endSpan(SpanNames.AUTHENTICATE_REQUEST, { error });

          // handle inline enrollment case
          if (error.code === ErrorCodes.AUTHENTICATOR_NOT_ENROLLED) {
            return of(
              startEnrollmentCeremony(),
              saveAuthnData(authnData),
              stopLoading(),
            );
          }

          tracer.endAllSpans();
          return of(mapErrorToAction(error));
        }),
      );
    }),
  );
};
