// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const SpanNames = {
  ACTIVATE_DEVICE: 'activate_device' as const,
  GET_DEVICE_FACTORS: 'get_device_factors' as const,
  GET_ORG_PREFERENCES: 'get_org_preferences' as const,
  GET_FACTOR_OPTIONS: 'get_factor_options' as const,

  CREATE_AUTHN_SESSION: 'create_authn_session' as const,
  AUTHENTICATE_REQUEST: 'authenticate_request' as const,

  CREATE_ENROLLMENT_SESSION: 'create_enrollment_session' as const,
  ENROLLMENT_REQUEST: 'enrollment_request' as const,

  SUBMIT_CREDENTIALS: 'submit_credentials' as const,

  GET_PUSH_STATUS: 'get_push_status' as const,
  PUSH_ACCEPTED: 'push_accepted' as const,
  GET_APP_ACCESS_CONTEXT: 'get_app_access_status' as const,
};
