// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { authenticationSessionEpic } from './authenticationSessionEpic';
import { enrollmentSessionEpic } from './enrollmentSessionEpic';

export const sessionsEpic = combineEpics(
  authenticationSessionEpic,
  enrollmentSessionEpic,
);
