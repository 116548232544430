// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  AuthnMethod,
  AgentEvent,
  FactorFilter,
  JourneyId,
  AgentEventHandler,
  AppAuthenticationStatusEventHandler,
  FactorDataReadyEventHandler,
} from '../types';
import { MemorySection } from '.';

export type GetEventHandler<EventType extends AgentEvent> =
  EventType extends AgentEvent.FACTOR_DATA_READY
    ? FactorDataReadyEventHandler
    : AppAuthenticationStatusEventHandler;

export class EventSection {
  constructor(private readonly _memory: MemorySection) {}

  on2(
    eventTypes: AgentEvent[],
    _: JourneyId,
    filter: FactorFilter,
    callback: AgentEventHandler,
  ) {
    eventTypes.forEach(eventType => {
      // 'factor-changed' and 'factor-data-ready' callbacks should be passed with the list of interested factors
      // if this list is missing - do nothing
      if (
        (eventType === AgentEvent.FACTOR_CHANGED ||
          eventType === AgentEvent.FACTOR_DATA_READY) &&
        !filter?.factorType.includes(AuthnMethod.PROX)
      ) {
        return;
      }

      // eslint-disable-next-line
      // @ts-ignore
      this._memory.registeredCallbacks[eventType] = callback;
    });
  }
}
