// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';

export const antLocales: { [key in string]: typeof enUS } = {
  en: enUS,
};

export const LocaleProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <ConfigProvider locale={antLocales[i18n.language || 'en']}>
      {children}
    </ConfigProvider>
  );
};
