import i18n from '../../i18n';
import { ModelData } from '../../types';

export const getLatestUsedDeviceModel = (modelData: ModelData[]): string => {
  if (modelData.length === 0) {
    return '';
  }

  modelData.sort(
    (a, b) =>
      new Date(a.lastUsedAt).getTime() - new Date(b.lastUsedAt).getTime(),
  );
  const latestDevice = modelData[modelData.length - 1];
  const deviceModel = latestDevice.deviceModel;
  const translatedDeviceModel = i18n.t(`data.${deviceModel}`, {
    ns: 'deviceModel',
    defaultValue: deviceModel,
  });
  return translatedDeviceModel;
};
