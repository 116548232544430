// Copyright 2022, Imprivata, Inc.  All rights reserved.

export function makeElementDragable(
  element: HTMLElement,
  header: HTMLElement,
  onChange?: (newCoords: { offSetTop: number; offSetLeft: number }) => void,
): void {
  let dMoveX = 0;
  let dMoveY = 0;
  let mouseX = 0;
  let mouseY = 0;

  let offSetTop = element.offsetTop;
  let offSetLeft = element.offsetLeft;

  header.onmousedown = dragMouseDown;

  function dragMouseDown(e: MouseEvent) {
    e.preventDefault();
    // get the mouse cursor position at startup:
    mouseX = e.clientX;
    mouseY = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: MouseEvent) {
    e.preventDefault();
    // calculate the new cursor position:
    dMoveX = mouseX - e.clientX;
    dMoveY = mouseY - e.clientY;
    mouseX = e.clientX;
    mouseY = e.clientY;

    offSetTop = element.offsetTop - dMoveY;
    offSetLeft = element.offsetLeft - dMoveX;
    element.style.top = `${offSetTop}px`;
    element.style.left = `${offSetLeft}px`;
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;

    if (onChange) {
      onChange({ offSetTop, offSetLeft });
    }
  }
}
