// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, switchMap, from, of, catchError, tap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { AuthnMethod } from '../../../../../types';
import { EpicDependencies } from '../../../../../store/types';
import { SpanNames, tracer } from '../../../../../tracing';
import {
  ErrorMessages,
  INVALID_SESSION_LOGOUT_CODES,
} from '../../../constants';
import {
  createAuthError,
  createAuthenticationSession,
  fatalError,
  stopLoading,
} from '../../actions';
import { Action } from '../../rootAction';
import { ILoginState } from '../../types';

export const authenticationSessionEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'getFactorTypes' | 'createSession'>
> = (action$, state$, deps) => {
  return action$.pipe(
    filter(isActionOf(createAuthenticationSession.request)),
    switchMap(action => {
      const { dispatchAfter, traceContext } = action?.payload || {};

      return from(deps.getFactorTypes()).pipe(
        switchMap(agentFactors => {
          const queryParams = new URLSearchParams(window.location.search);
          const contextType = queryParams.get('contextType') || '';
          const resourceType = queryParams.get('resourceType') || '';
          const methods = [
            ...agentFactors,
            AuthnMethod.USERNAME_PASSWORD,
            AuthnMethod.IMPR_ID,
          ];

          if (traceContext) {
            tracer.startSpanFromContext(
              SpanNames.CREATE_AUTHN_SESSION,
              traceContext,
            );
          } else {
            tracer.startSpan(SpanNames.CREATE_AUTHN_SESSION);
          }

          const { fullUsername } = state$.value;
          return from(
            deps.createSession(
              methods,
              {
                contextType,
                resourceType,
                samlData: action?.payload?.samlData || '',
              },
              false,
              fullUsername,
            ),
          ).pipe(
            tap(response =>
              tracer.endSpan(SpanNames.CREATE_AUTHN_SESSION, { response }),
            ),
          );
        }),
        switchMap(response => {
          const actions: Action[] = [
            createAuthenticationSession.success(response.data.sessionId),
          ];

          if (dispatchAfter) {
            actions.push(dispatchAfter as Action);
          }

          return from(actions);
        }),
        catchError(error => {
          tracer.endSpan(SpanNames.CREATE_AUTHN_SESSION, { error });

          if (INVALID_SESSION_LOGOUT_CODES.includes(error.code)) {
            return of(
              fatalError({
                errorMessage: ErrorMessages.SESSION_EXPIRED,
                retryConnection: true,
              }),
              stopLoading(),
            );
          } else {
            return of(
              createAuthError({
                errorMessage: ErrorMessages.INCORRECT_TENANT_ID,
              }),
              stopLoading(),
            );
          }
        }),
      );
    }),
  );
};
