// Copyright 2022, Imprivata, Inc.  All rights reserved.

export function stringToBase64(stringToEncode: string): string {
  return Buffer.from(stringToEncode).toString('base64');
}

export function base64ToString(base64String: string): string {
  return Buffer.from(base64String, 'base64').toString('utf8');
}

export function base64ToJson(base64String: string): Record<string, unknown> {
  return JSON.parse(base64ToString(base64String));
}

export function jsonToBase64(jsonObject: unknown): string {
  return stringToBase64(JSON.stringify(jsonObject));
}

export function encodeObjectProperties<T extends Record<string, unknown>>(
  obj: T,
): { [key in keyof T]: string } {
  const resultObject: Record<keyof T, string> = {} as Record<keyof T, string>;
  Object.entries(obj).forEach(([key, value]) => {
    resultObject[key as keyof T] = jsonToBase64(value);
  });
  return resultObject;
}
