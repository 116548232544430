// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Space, Typography, Input, Tooltip } from 'antd';
import { usePersistentSessionState } from '../../../hooks';
import {
  base64ToString,
  jsonToBase64,
} from '../../../../authn-module/utils/base64';
import { useState } from 'react';

export const AppContextSection = () => {
  const [state, setState] = usePersistentSessionState({
    app_access_context: '',
  });
  const [encodingError, setEncodingError] = useState(false);

  return (
    <Space direction="vertical" style={{ padding: 10 }}>
      <Typography.Title level={4}>AppContext</Typography.Title>
      <Space direction="horizontal">
        <Input
          status={encodingError ? 'error' : ''}
          style={{ width: '280px' }}
          defaultValue={base64ToString(state.app_access_context)}
          onChange={event => {
            try {
              const encodedContext = JSON.parse(event.target.value);
              setState({
                app_access_context: jsonToBase64(encodedContext),
              });
              setEncodingError(false);
            } catch {
              setEncodingError(true);
            }
          }}
        />

        {encodingError && (
          <Tooltip title="String is not in correct JSON format">
            <span>(!)</span>
          </Tooltip>
        )}
      </Space>
    </Space>
  );
};
