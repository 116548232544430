// Copyright 2022, Imprivata, Inc.  All rights reserved.

// TODO: delete these value and provide it in env variables as in Admin UI
import { ApiVersion } from './authn-module/api/constants';
import { QueryParams } from './authn-module/pages/login/constants';
import {
  getUrlForApi,
  parseDeploymentDirective,
} from '@imprivata-cloud/deployment-directives-js';

export const MOCKSERVER_DEFAULT_URL = 'http://localhost:1080';

declare global {
  interface Window {
    _appConfig: Record<string, string>;
  }
}

export type AppEnvConfig = {
  API_URL: string;
  AUTHN_API_VERSION: ApiVersion;
  ZIPKIN_TRACING_URL?: string;
  OTLP_TRACING_URL?: string;
  COLLECT_TRACING?: string;
  TEST_UTILITY_ENABLED?: string;
  LOGGING_FOR_AGENT_ENABLED?: string;
};

let envConfig: AppEnvConfig;

export function _getEnvVariable(
  window: Window,
  env: NodeJS.ProcessEnv,
  name: string,
  defaultValue?: string,
): string | undefined {
  const reactAppEnvName = `REACT_APP_${name}`;

  if (window._appConfig?.hasOwnProperty(name)) {
    return window._appConfig[name];
  }

  if (env.hasOwnProperty(reactAppEnvName) || env.hasOwnProperty(name)) {
    return env[reactAppEnvName] || env[name];
  }

  return defaultValue;
}

export function _initAppConfig(
  window: Window,
  env: NodeJS.ProcessEnv,
): AppEnvConfig {
  let authnApiVersion: ApiVersion;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(QueryParams.AUTHN_API_VERSION)) {
    authnApiVersion = urlParams.get(
      QueryParams.AUTHN_API_VERSION,
    ) as ApiVersion;
  } else {
    authnApiVersion = _getEnvVariable(
      window,
      env,
      'API_VERSION',
      ApiVersion.V2,
    ) as ApiVersion;
  }
  let apiURL;
  const deploymentDirective = _getEnvVariable(
    window,
    env,
    'DEPLOYMENT_DIRECTIVE',
  ) as string;
  if (deploymentDirective) {
    const parsedDirective = parseDeploymentDirective(deploymentDirective);
    apiURL = getUrlForApi(parsedDirective, 'astra');
  }
  return {
    API_URL:
      apiURL ||
      (_getEnvVariable(
        window,
        env,
        'API_URL',
        MOCKSERVER_DEFAULT_URL,
      ) as string),
    AUTHN_API_VERSION: authnApiVersion,
    ZIPKIN_TRACING_URL: _getEnvVariable(window, env, 'ZIPKIN_TRACING_URL'),
    OTLP_TRACING_URL: _getEnvVariable(window, env, 'OTLP_TRACING_URL'),
    COLLECT_TRACING: _getEnvVariable(window, env, 'COLLECT_TRACING'),
    TEST_UTILITY_ENABLED: _getEnvVariable(window, env, 'TEST_UTILITY_ENABLED'),
    LOGGING_FOR_AGENT_ENABLED: _getEnvVariable(
      window,
      env,
      'LOGGING_FOR_AGENT_ENABLED',
    ),
  };
}

export default function getConfig(): AppEnvConfig {
  if (!envConfig) {
    envConfig = _initAppConfig(window, process.env);
  }
  return envConfig;
}
