// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect, useMemo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from './store/configureStore';
import { LocaleProvider } from './i18n/localeProvider';
import App from './App';

import { OuterDependencies } from './store/types';
import { saveUsername, startReset } from './pages/login/store/actions';
import {
  AuthBannerSize,
  AuthBannerType,
} from '@imprivata-cloud/astra-ui-component-library';
import i18n from './i18n';
import { AppAccessContext } from './types';

export type AuthnModuleProps = {
  dependencies: OuterDependencies;
  error: boolean;
  appAccessContext: AppAccessContext | null;
};

export const AuthnModule = (props: AuthnModuleProps) => {
  const { dependencies, error, appAccessContext } = props;

  const store = useMemo(() => {
    return configureStore(dependencies);
  }, [dependencies]);

  useEffect(() => {
    if (error) {
      store.dispatch(
        startReset({
          banners: [
            {
              type: AuthBannerType.ERROR,
              primary: i18n.t('errors.SOMETHING_UNEXPECTED'),
              size: AuthBannerSize.TALL,
            },
          ],
        }),
      );
    }
  }, [store, error]);

  useEffect(() => {
    if (appAccessContext?.username) {
      store.dispatch(saveUsername(appAccessContext.username));
    }
  }, [store, appAccessContext]);

  return (
    <LocaleProvider>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </LocaleProvider>
  );
};
