import { Epic } from 'redux-observable';
import { of, filter, switchMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  clearErrors,
  pushAccepted,
  pushNotificationAcceptedSuccess,
} from '../actions';
import { Action } from '../rootAction';
import { ILoginState } from '../types';
import { EpicDependencies } from '../../../../store/types';

export const pushAcceptedEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'onSuccess'>
> = (action$, state$, dependencies) => {
  const { onSuccess } = dependencies;

  return action$.pipe(
    filter(isActionOf(pushAccepted)),
    switchMap(({ payload }) => {
      const { samlToken } = payload;

      onSuccess({
        saml: samlToken,
      });

      return of(clearErrors(), pushNotificationAcceptedSuccess());
    }),
  );
};
