// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createStore, applyMiddleware, compose, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import reducer from './reducer';
import { rootEpic } from './epics';
import { Action, EpicDependencies, RootState } from './types';
import {
  authenticationStatus$,
  endJourneyAndCloseWindow,
  getAppAccessContext,
  showWindow,
} from '../agent-api';
import { Observable } from 'rxjs';
import { TraceContext } from '../authn-module/types';

const isProduction = process.env.NODE_ENV === 'production';

const composeEnhancers =
  (!isProduction &&
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Hosting App' })) ||
  compose;

export const configureStore = (): Store<RootState> => {
  const epicMiddleware = createEpicMiddleware<
    Action,
    Action,
    RootState,
    EpicDependencies
  >({
    dependencies: {
      authenticationStatus$,
      endJourneyAndCloseWindow,
      showWindow,

      getAppAccessContext$: (journeyId: string, traceContext: TraceContext) =>
        new Observable(subscription =>
          getAppAccessContext(
            journeyId,
            traceContext,
            (appAccessContext: string) => {
              subscription.next(appAccessContext);
              subscription.complete();
            },
          ),
        ),
    },
  });

  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);

  return store;
};
