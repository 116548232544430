// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { Button, InputBox } from '@imprivata-cloud/astra-ui-component-library';
import { useTranslation } from 'react-i18next';
import passwordImg from '../../../images/passwordImg.svg';

interface IProps {
  initValue?: string;
  error?: string;
  disabled?: boolean;
  onSubmit: (password: string) => void;
  passwordLabel?: string;
}

export const PasswordForm = ({
  initValue = '',
  disabled = false,
  onSubmit,
  passwordLabel = '',
}: IProps): JSX.Element => {
  const [password, setPassword] = React.useState(initValue);
  const { t } = useTranslation();
  useEffect(() => {
    setPassword(initValue);
  }, [initValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value.trim());
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(password);
    const passwordFocus = e.currentTarget.elements.namedItem(
      'passwordInput',
    ) as HTMLInputElement;
    passwordFocus.focus();
  };

  const finalizedLabel = passwordLabel
    ? t('login.password.custom-label', {
        password: passwordLabel,
        // Prevent HTML Entities from being screened twice - by i18n function and then by react
        interpolation: { escapeValue: false },
      })
    : t('login.password.label');

  return (
    <form onSubmit={handleSubmit}>
      <label
        htmlFor="passwordInput"
        data-testid="password-input-label"
        className="formLabel subtitle"
      >
        {finalizedLabel}
      </label>

      <img
        src={passwordImg}
        alt="passwordImg"
        style={{ marginBottom: '36px' }}
      />
      <InputBox
        type="password"
        name="passwordInput"
        placeholder={''}
        value={password}
        data-testid={'password-input'}
        onChange={handleChange}
        autoFocus
        disabled={disabled}
        width="100%"
        size="large"
        id="passwordInput"
      />
      <Button
        astraButtonType={['major']}
        label={t('actions.continue')}
        disabled={!password.length || disabled}
        data-testid="submit-button"
        type="primary"
        block={true}
        htmlType="submit"
        id="submitButton"
      />
    </form>
  );
};
