// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, switchMap, Observable } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  clearErrors,
  fetchFactorOptions,
  subscribeToFactorChangedAction,
} from '../actions';
import { Action } from '../rootAction';
import { EpicDependencies } from '../../../../store/types';
import { ILoginState } from '../types';

export const factorChangeEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<EpicDependencies, 'subscribeToFactorChanged'>
> = (action$, state$, dependencies) => {
  const { subscribeToFactorChanged } = dependencies;

  return action$.pipe(
    filter(isActionOf(subscribeToFactorChangedAction)),
    switchMap(() => {
      return new Observable<Action>(subscription => {
        subscribeToFactorChanged(
          (_, { traceContext, factorList: agentFactors }) => {
            const isSessionStarted = state$.value.sessionId;
            if (!isSessionStarted) {
              subscription.next(clearErrors());
              subscription.next(
                fetchFactorOptions.request({ agentFactors, traceContext }),
              );
            }
          },
        );
      });
    }),
  );
};
