// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, from, mergeMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { startAppInit } from '../actions';
import { Action } from '../rootAction';

export const initEpic: Epic<Action> = action$ => {
  return action$.pipe(
    filter(isActionOf(startAppInit)),
    mergeMap(() => {
      return from([]);
    }),
  );
};
