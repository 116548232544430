// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Epic } from 'redux-observable';
import { filter, switchMap, from, of, catchError } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { ApiError, AuthnMethod } from '../../../../types';
import { EpicDependencies } from '../../../../store/types';
import { SpanNames, tracer } from '../../../../tracing';
import { ErrorCodes, ErrorMessages, QueryParams } from '../../constants';
import {
  createSessionError,
  fetchFactorOptions,
  saveFactorOptions,
} from '../actions';
import { Action } from '../rootAction';
import { ILoginState } from '../types';

export const fetchFactorOptionsEpic: Epic<
  Action,
  Action,
  ILoginState,
  Pick<
    EpicDependencies,
    'getFactorTypes' | 'getQueryParams' | 'getFactorOptions'
  >
> = (action$, state$, dependencies) => {
  const { getFactorTypes, getQueryParams, getFactorOptions } = dependencies;

  return action$.pipe(
    filter(isActionOf(fetchFactorOptions.request)),
    switchMap(({ payload }) => {
      return of({ payload });
    }),
    switchMap(() => {
      return from(getFactorTypes()).pipe(
        switchMap(agentFactors => {
          const queryParams = getQueryParams();

          const requiredParams = [
            QueryParams.TENANT_ID,
            QueryParams.CONTEXT_TYPE,
            QueryParams.RESOURCE_TYPE,
          ];

          if (!requiredParams.every(param => queryParams.get(param))) {
            return of(createSessionError(ErrorMessages.MISSING_QUERY_PARAMS));
          }

          const tenantId = queryParams.get(QueryParams.TENANT_ID) || '';
          const contextType = queryParams.get(QueryParams.CONTEXT_TYPE) || '';
          const resourceType = queryParams.get(QueryParams.RESOURCE_TYPE) || '';
          const methods: AuthnMethod[] = [
            ...agentFactors,
            AuthnMethod.USERNAME_PASSWORD,
            AuthnMethod.IMPR_ID,
          ];
          const { fullUsername } = state$.value;

          tracer.startSpan(SpanNames.GET_FACTOR_OPTIONS);
          return from(
            getFactorOptions(
              tenantId,
              methods,
              contextType,
              resourceType,
              fullUsername,
            ),
          ).pipe(
            switchMap(response => {
              tracer.endSpan(SpanNames.GET_FACTOR_OPTIONS, { response });
              const { factorOptions } = response.data;
              return of(
                saveFactorOptions({
                  tenantId,
                  authnMethods: factorOptions,
                }),
                fetchFactorOptions.success(
                  factorOptions.map(factor => factor.factorType),
                ),
              );
            }),
            catchError((error: ApiError) => {
              if (error.code === ErrorCodes.FACTORS_INSUFFICIENT) {
                // when authnMethods array is empty - UI will be hidden by the agent
                // so that no need to show error
                return of(saveFactorOptions({ tenantId, authnMethods: [] }));
              }

              return of(createSessionError(ErrorMessages.INCORRECT_TENANT_ID));
            }),
          );
        }),
      );
    }),
  );
};
