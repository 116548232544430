// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';

export const GeneralMessage = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      data-testid="general-message"
      className="generalMessage"
      style={{ visibility: 'hidden' }}
    >
      {children}
    </div>
  );
};
